import React, { useState } from "react";
import { Col, Row, Modal, Button } from "react-bootstrap";
import mobile from "../../Images/mobile.png";
import deleteicon from "../../Images/delete.png";
import DeleteCartHook from "../../hook/cart/DeleteCartHook";
import ViewAllUsersHook from "../../hook/admin/ViewAllUsersHook";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
const CartItem = ({ item }) => {
  // console.log(item);
  // console.log('-------------------------');
  const { t } = useTranslation();

  const [
    handleDeletCart,
    show,
    handleClose,
    handleShow,
    handleDeleteItem,
    itemCount,
    onChangeCount,
    handleUpdateCart,
    itemNotes,
    onChangeItemNotes,
  ] = DeleteCartHook(item);
  const settingsRes = useSelector((state) => state.settingsReducers.settings);

  const [users, clickHandler, webSiteData, handleShowFun, showReviews] =
    ViewAllUsersHook();

  return (
    <Col xs="12" className="cart-item-body my-2 d-flex px-2 mb-5">
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>
            <div className="font"> {t("confirm delete")} </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="font">
            {t("Are you sure to remove the product from the cart")}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="font" variant="secondary" onClick={handleClose}>
            {t("retreat")}
          </Button>
          <Button className="font" variant="danger" onClick={handleDeleteItem}>
            {t("delete")}
          </Button>
        </Modal.Footer>
      </Modal>

      <img
        width="160px"
        height="250px"
        src={(item && item.product.image) || mobile}
        alt=""
        style={{ objectFit: "contain" }}
      />
      <div className="w-100 mx-2">
        <Row className="justify-content-between">
          <Col sm="12" className=" d-flex flex-row justify-content-between">
            <div className="d-inline pt-2 cat-text">
              {item && Cookies.get("i18next") === "ar"
                ? item?.product?.category?.nameAr || " "
                : item?.product?.category?.nameEn || " "}
            </div>
            <div
              onClick={handleShow}
              className="d-flex pt-2 "
              style={{ cursor: "pointer" }}
            >
              <img src={deleteicon} alt="" width="20px" height="24px" />
              <div className="cat-text d-inline me-2"> {t("move")} </div>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center mt-2">
          <Col sm="12" className=" d-flex flex-row justify-content-start">
            <div className="d-inline pt-2 cat-title">
              {item && Cookies.get("i18next") === "ar"
                ? item?.product?.nameAr || " "
                : item?.product?.nameEn || " "}
            </div>

            {showReviews ? (
              <div className="d-inline pt-2 cat-rate me-2">
                {(item && (item?.product?.ratingsAverage).toFixed(2)) || 0}
              </div>
            ) : (
              <div></div>
            )}
          </Col>
        </Row>
        <Row>
        {
          settingsRes?.data?.showBrand === true?(
            <Col sm="12" className="mt-1">
            <div className="cat-text d-inline"> {t("Brand")} </div>
            <div className="barnd-text d-inline mx-1">
              {item && Cookies.get("i18next") === "ar"
                ? item?.product?.brand?.nameAr || " "
                : item?.product?.brand?.nameEn || " "}
            </div>
          </Col>
          ):null
        }
        
        </Row>
        <Row>
          <Col sm="12" className="mt-1 d-flex">
            {item && item.color === "" ? null : (
              <div
                className="color ms-2 border"
                style={{ backgroundColor: `${item && item.color}` }}
              ></div>
            )}
            {item && item.classification === "" ? null : (
              <div
                className=" ms-2 d-flex justify-content-center align-items-center file-input-label-text p-1 mb-2"
                style={{
                  width: "60px",
                  borderRadius: "5px",
                  boxShadow: "rgb(151 151 151) 0px 4px 4px 0px",
                }}
              >
                {item.classification}{" "}
              </div>
            )}
          </Col>
        </Row>

        <Row className="justify-content-between">
          <Col sm="12" className=" d-flex flex-row justify-content-between flex-column  justify-content-end">
            <div className="d-inline pt-2 d-flex">
              <div className="cat-text  d-inline"> {t("Quantity")} </div>
              <input
                value={itemCount}
                onChange={onChangeCount}
                className="mx-2 text-center"
                type="number"
                style={{ width: "40px", height: "25px" }}
              />
              <button
                className="product-cart-add"
                style={{
                  height: "25px",
                  fontSize: "12px",
                  backgroundColor: webSiteData.PRIMARY_COLOR,
                }}
                onClick={handleUpdateCart}
              >
                {t("apply")}
              </button>
            </div>

            <div className="d-inline pt-2 barnd-text">
              {item?.product?.priceOffer === 0 ? (
                <>
                  {(item && item?.product?.price1 * item?.quantity) || ""}{" "}
                  {t("currency")}
                </>
              ) : (
                <>
                  {(item && item?.product?.priceOffer * item?.quantity) || ""}{" "}
                  {t("currency")}
                </>
              )}
            </div>
          </Col>
          <Col>
            <div className="mt-1 d-flex  align-items-center w-100 " >
              <textarea
                id="richDescriptionAr"
                onChange={onChangeItemNotes}
                value={itemNotes}
                className="input-form-area p-2 m-1 w-100"
                placeholder={t("add notes")}
                
              />

              <button
                className="product-cart-add "
                style={{
                  height: "25px",
                  fontSize: "12px",
                  backgroundColor: webSiteData.PRIMARY_COLOR,
                }}
                onClick={handleUpdateCart}
              >
                {t("apply")}
              </button>
            </div>
          </Col>
        </Row>
      </div>
    </Col>
  );
};

export default CartItem;
