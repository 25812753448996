import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBrandById } from "../../redux/actions/brandAction";
import { getProductsByBrand } from "../../redux/actions/productsAction";
import {
  getAllVendorProductsById,
  getVendorByusername,
} from "../../redux/actions/vendorActions";

const ViewProductsByVendorHook = (vendorname) => {
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const getById = async () => {
    setLoading(true);
    await dispatch(getVendorByusername(vendorname));
    setLoading(false);
  };

  useEffect(() => {
    getById();
  }, []);

  useEffect(() => {
    if (loading === false) {
      dispatch(getAllVendorProductsById(vendorByusername._id));
    }
  }, [loading]);

  const allproduct = useSelector(
    (state) => state.vendorReducer.getProductsByVendorId
  );

  const vendorByusername = useSelector(
    (state) => state.vendorReducer.vendorByUsername
  );

  let items = [];

  try {
    if (allproduct)
      if (allproduct) items = allproduct;
      else items = [];
  } catch (error) {}

  return [items, vendorByusername, loading];
};

export default ViewProductsByVendorHook;
