import React from "react";
import { Button, Card, Col, Modal } from "react-bootstrap";
import { ToastContainer } from "react-toastify";

import rate from "../../Images/rate.png";

import ProductCardHook from "../../hook/product/ProductCardHook";
import AddToCartHook from "../../hook/cart/AddToCartHook";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";

const ProductCard = ({ item, favProduct }) => {

  const settingsRes = useSelector((state) => state.settingsReducers.settings);
  const [favImg, handleFav] = ProductCardHook(item, favProduct);
  const { t } = useTranslation();
  const currencytype =
    Cookies.get("i18next") == "ar"
      ? settingsRes.data?.defaultCurrancy.ar
      : settingsRes.data?.defaultCurrancy.en;
  const [
    colorClick,
    indexColor,
    handleAddToCart,
    ,
    showReviews,
    onClickProduct,
    show,
    handleClose,
    sizeClick,
    sizeIndex,
    addProductColorSizeToCart,
  ] = AddToCartHook(item._id, item);

 
  return (
    <Col xs="6" sm="6" md="4" lg="3" className="d-flex">
      <Modal show={show} onHide={handleClose} className="sizeModal">
        <Modal.Header>
          <Modal.Title>
            <div className="font">{t("Colors and sizes")}</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {item?.colors.length >= 1 && (
            <>
              <div className="file-input-label-text font">
                {t("Available colors")}
              </div>
              <div md="12" className="mt-1 d-flex flex-wrap">
                {item.colors
                  ? item.colors.map((el, index) => (
                      <div
                        key={index}
                        onClick={() => colorClick(index, el)}
                        className="color ms-2 d-flex"
                        style={{
                          backgroundColor: el,
                          border:
                            indexColor === index ? "2px solid black" : null,
                          width: "20px",
                          height: "20px",
                          boxShadow: "rgb(151 151 151) 0px 4px 4px 0px",
                        }}
                      ></div>
                    ))
                  : null}
              </div>
            </>
          )}

          {item?.classifications.length >= 1 && (
            <>
              <div className="file-input-label-text font">
                {t("Available sizes")}
              </div>
              <div md="12" className="mt-2 d-flex flex-wrap">
                {item.colors
                  ? item.classifications.map((el, index) => (
                      <div
                        key={index}
                        onClick={() => sizeClick(index, el)}
                        className=" ms-2 d-flex justify-content-center align-items-center file-input-label-text p-1 mb-1"
                        style={{
                          width: "60px",
                          border:
                            sizeIndex === index ? "3px solid black" : null,
                          borderRadius: "5px",
                          cursor: "pointer",
                          boxShadow: "rgb(151 151 151) 0px 4px 4px 0px",
                        }}
                      >
                        {el}
                      </div>
                    ))
                  : null}
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button className="font" variant="secondary" onClick={handleClose}>
            {t("retreat")}
          </Button>
          <Button
            className="font"
            variant="success"
            onClick={() => addProductColorSizeToCart()}
          >
            {t("Confirmation")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Card
        className="my-1"
        style={{
          width: "100%",
          height: "auto",
          borderRadius: "8px",
          border: "none",
          backgroundColor: "#FFFFFF",
          boxShadow: "0 2px 2px 0 rgba(151,151,151,0.5)",
        }}
      >
        {item.countInStock == 0 ? (
          <div
            style={{
              backgroundColor: "#9A0E2A",
              color: "while",

              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "center",
              position: "absolute",
              top: "35%",
              translate: "(-50% , 50%)",
              opacity: ".6",
            }}
          >
            <span className="text-uppercase px-4 py-1 text-white  text-center  ">
              {t("product not found in stock")}
            </span>
          </div>
        ) : (
          ""
        )}
        <div className="d-flex justify-content-between mx-1  py-1">
          {showReviews ? (
            <div className="d-flex">
              <img className="" src={rate} alt="" height="16px" width="16px" />
              <div className="card-rate mx-1">
                {item.ratingsAverage && item.ratingsAverage.toFixed(1)}
              </div>
            </div>
          ) : (
            <div></div>
          )}

          <img
            onClick={handleFav}
            src={favImg}
            alt=""
            className="text-center"
            style={{
              height: "24px",
              width: "26px",
              cursor: "pointer",
            }}
          />
        </div>

        <Card.Img
          onClick={() => onClickProduct(item._id)}
          style={{
            height: "215px",
            width: "95%",
            objectFit: "contain",
            cursor: "pointer",
            alignSelf: "center",
          }}
          src={
            item.image === ""
              ? "http://shelly-ksa.com/public/uploads/shellyLogo.jpg-1674397778957.jpeg"
              : item.image
          }
        />
        <Card.Body>
          <Card.Title>
            <div
              onClick={() => onClickProduct(item._id)}
              title={
                Cookies.get("i18next") === "ar" ? item.nameAr : item.nameEn
              }
              className="card-title"
            >
              {Cookies.get("i18next") === "ar" ? item.nameAr : item.nameEn}
            </div>
          </Card.Title>
          <Card.Text>
            <div className="d-flex justify-content-between ">
              <div className="d-flex">
                <div
                  className="card-price"
                  style={{ color: settingsRes?.data?.primaryColor }}
                >
                  {item?.priceOffer === 0 ? (
                    <>
                      <div className="d-flex">
                        {item.price1}
                        <div className="card-currency mx-1">{currencytype}</div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="d-flex">
                        <del className="d-block" style={{color:'red'}}>{item.price1}</del>
                        <div className="card-currency mx-1">{currencytype}</div>
                      </div>

                      <div className="d-flex">
                        {item.priceOffer}
                        <div className="card-currency mx-1">{currencytype}</div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div md="12" className="d-flex justify-content-end mt-1">
              {/*
             <div md="12" className="mt-1 d-flex justify-content-center">
                {item.colors
                  ? item.colors.slice(0, 2).map((el, index) => (
                      <div
                        key={index}
                        onClick={() => colorClick(index, el)}
                        className="color ms-2"
                        style={{
                          backgroundColor: el,
                          border:
                            indexColor === index ? "2px solid black" : null,
                          width: "20px",
                          height: "20px",
                          boxShadow: "rgb(151 151 151) 0px 4px 4px 0px",
                        }}
                      ></div>
                    ))
                  : null}
              </div>
            */}

              <div
                onClick={handleAddToCart}
                className="product-cart-add-icon p-1 px-2  "
                style={{ backgroundColor: settingsRes?.data?.primaryColor }}
              >
                <i className="fa-solid fa-cart-arrow-down"></i>
              </div>
            </div>
          </Card.Text>
        </Card.Body>
      </Card>
      <ToastContainer />
    </Col>
  );
};

export default ProductCard;

// <span
//             className="text-uppercase position-absolute px-4 py-1 text-white  text-center  "
//             style={{
//               backgroundColor: "#000",
//               rotate: "-45deg",
//               right: "133px",
//               top: "53px",
//               borderRadius: "5px",
//             }}
//           ></span>
