import React, { Fragment, useEffect, useState } from "react";
import {
  Container,
  FormControl,
  Nav,
  Navbar,
  NavDropdown,
} from "react-bootstrap";

import shoppingCart from "../../Images/shopping-cart.png";
import user from "../../Images/user.png";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import NavBarSearchHook from "../../hook/search/NavBarSearchHook";
import UserCartHook from "../../hook/cart/UserCartHook";
import ReactGA from "react-ga";
import GetConfigDataHook from "../../hook/configer/GetConfigDataHook";
import { BsQuestionCircleFill } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import { Link , NavLink } from "react-router-dom";


const NavBarLogin = () => {
  const [onChangeSearch, searchWord] = NavBarSearchHook();
  const [webSiteData, settings] = GetConfigDataHook()
const location = useLocation()
  const languages = [
    {
      code: "en",
      name: "English",
      dir: "ltr",
    },
    {
      code: "ar",
      name: "العربية",
      dir: "rtl",
    },
  ];
  const [t, i18n] = useTranslation();
  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);


  const handleGoogleEvent = () => {
    ReactGA.event({
      category: "cart",
      action: "cart_details_action",
      label: "cart_label",
    });
  };

  useEffect(() => {
    // var titleAr = settingsRes?.data?.titleAr
    // document.title = titleAr ? titleAr : ''
    document.body.dir = currentLanguage.dir || "ltr";
  }, [currentLanguage]);
  // let word = "";
  // if (localStorage.getItem("searchWord") != null) {
  //   word = localStorage.getItem("searchWord");
  // }
  const [userData, setUserData] = useState("");

  useEffect(() => {
    if (localStorage.getItem("user") != null) {
      setUserData(JSON.parse(localStorage.getItem("user")));
    }
  }, []);

  const logOut = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("page");
    localStorage.removeItem("token");

    setUserData("");
  };
  const [res] = UserCartHook();

  // console.log(res?.numberOfCart)
  // console.log(useSelector((state) => state.cartReducer.userCartItems));
const navigate = useNavigate()
  const navProduct = () => {
    navigate('/admin/products')
  }

  const navLogout = () => {
    navigate('/admin/products')

  }


  return (
    <Navbar
      className="sticky-top "
      expand="sm"
      style={{ backgroundColor: settings?.data?.primaryColor }}
    >
      <Container>
        <Navbar.Brand>
          <Link to="/">
            <img alt="Logo" src={settings?.data?.logo} className="logo" />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <FormControl
            onChange={onChangeSearch}
            value={searchWord}
            type="search"
            placeholder={t("search placeholder")}
            className="me-2 w-100"
            aria-label="Search"
          />
          <Nav className="me-auto">
            {i18n.language === "en" ? (
              <Nav.Link
                onClick={() => i18n.changeLanguage("ar")}
                className="nav-text langText d-flex mt-3 justify-content-center"
              >
                عربي
              </Nav.Link>
            ) : (
              <Nav.Link
                onClick={() => i18n.changeLanguage("en")}
                className="nav-text langText d-flex mt-3 justify-content-center"
              >
                English
              </Nav.Link>
            )}
            {userData != "" ? (
              <NavDropdown title={userData.firstname} id="basic-nav-dropdown"
                menuVariant="dark"
              >
                <div style={{
                  backgroundColor: `${settings?.data?.primaryColor}`
                }}>

                  {userData.role === "admin" ? (
                    <NavDropdown.Item onClick={navProduct}>
                    <Link to="/admin/products"   style={{ textDecoration: "none", color: '#000' }}>
                    {t("control panel")}
                    </Link>
                     
                    </NavDropdown.Item>
                  ) : userData.role === "user" ? (
                    <Fragment>
                      <NavDropdown.Item
                        style={{
                          borderBottomWidth: "1px",
                          borderBottomStyle: "solid",
                        }}
                        onClick={()=>navigate('/user/profile')}
                      >
                      <Link to="/user/profile"   style={{ textDecoration: "none", color: '#000' }}>
                      {t("profile")}
                      </Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item
                      onClick={()=>navigate('/user/allorders')}
                        style={{
                          borderBottomWidth: "1px",
                          borderBottomStyle: "solid",
                        }}
                      >
                      <Link to="/user/allorders"   style={{ textDecoration: "none", color: '#000' }}>
                      {t("orders")}
                      </Link>
                        
                      </NavDropdown.Item>
                      <NavDropdown.Item
                      onClick={()=>navigate('/user/favoriteproducts')}

                        style={{
                          borderBottomWidth: "1px",
                          borderBottomStyle: "solid",
                        }}
                      >
                      <Link to="/user/favoriteproducts"   style={{ textDecoration: "none", color: '#000' }}>
                      {t("Prefered products")}
                      </Link>
                        
                      </NavDropdown.Item>
                      <NavDropdown.Item
                      onClick={()=>navigate('/user/addresses')}

                        style={{
                          borderBottomWidth: "1px",
                          borderBottomStyle: "solid",
                        }}
                      >
                      <Link to="/user/addresses"   style={{ textDecoration: "none", color: '#000' }}>
                      {t("personal addresses")}
                      </Link>
                        
                      </NavDropdown.Item>
                    </Fragment>
                  ) : (
                    <NavDropdown.Item href="/vendor/profile">
                      {t("profile")}
                    </NavDropdown.Item>
                  )}
                  <NavDropdown.Divider />
                  <NavDropdown.Item  >
                  <Link onClick={logOut} to="/login"   style={{ textDecoration: "none", color: '#000' }}>
                  {t("logout")}
                  </Link>
                  </NavDropdown.Item>
                </div>

              </NavDropdown>
            ) : (
              <NavLink
                to="/login"
                style={({ isActive }) => ({
                  backgroundColor: isActive ? "transparent" : null,
                  textDecoration: isActive ? "none" : "none",
                  borderRight: isActive ? "none" : null,
                  marginBottom:isActive ? '10px' : '10px',

                })}
                className="nav-text d-flex mt-3 justify-content-center"
              >
                <p  style={{ color: "black" , marginTop:'7px' }}>{t("login")}</p>
                <img style={{marginTop:'6px' }} src={user} className="login-img" alt="sfvs" />
              </NavLink>
            )}

            {
              userData.role === "user" && <NavLink
                to="/cart"
                className="nav-text d-flex mt-3 justify-content-center position-relative"
                style={({ isActive }) => ({
                  backgroundColor: isActive ? "transparent" : null,
                  textDecoration: isActive ? "none" : "none",
                  borderRight: isActive ? "none" : null,
                  marginBottom:isActive ? '10px' : '10px',
                  color:isActive ? 'black' : 'black',

                })}
                  onClick={handleGoogleEvent}
              >
                <p style={{ color: "black" }}>{t("cart")}</p>
                <img src={shoppingCart} className="login-img" alt="sfvs" />

                <span className="position-absolute top-5 start-0 translate-middle badge rounded-pill bg-danger">
                  {res?.numberOfCart || 0}
                </span>
              </NavLink>
            }

            {
              (userData.role === "admin" ) && ( location.pathname.startsWith('/admin/')) &&
               <Nav.Link
                href="/admin/learning"
                className="nav-text d-flex mt-2 justify-content-center position-relative"
               
              >
              <span tabindex="0" data-toggle="tooltip" title={t("Go to the educational page")}  as="p" animation="wave" variant="info" className="text-light fs-5 d-inline-block" ><BsQuestionCircleFill/></span> 
              </Nav.Link>
            }
            {
              ( userData.role === "vendor" ) && ( location.pathname.startsWith('/vendor/')) &&
               <Nav.Link
                href="/vendor/learning"
                className="nav-text d-flex mt-2 justify-content-center position-relative"
               
              >
              <span tabindex="0" data-toggle="tooltip" title={t("Go to the educational page")}  as="p" animation="wave" variant="info" className="text-light fs-5 d-inline-block" ><BsQuestionCircleFill/></span> 
              </Nav.Link>
            }

          </Nav>

        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBarLogin;
