import React from "react";
import { Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PaymentStatusHook from "../../hook/user/PaymentStatusHook";

const PaymentStatus = () => {
    const { t } = useTranslation()
    const [responseObject] = PaymentStatusHook();

    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-5">
                    <div  className={responseObject.ResponseCode === '000' ? '_success message-box' : '_success _failed message-box'}>
                        <i className="fa fa-check-circle" aria-hidden="true"></i>
                        <h2> {responseObject.ResponseCode === '000' ? t("Your payment was successful") : t("Your payment failed")} </h2>
                        {
                            responseObject.ResponseCode === '000' ? (<p>{t("Thank you for your payment. we will be in contact with more details shortly")}  </p>) : <p> {t("Try again later")}  </p>
                        }
                    </div>
                </div>
            </div>
            <hr />
        </div>
    )
};

export default PaymentStatus;
