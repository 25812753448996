import React, { useState, useEffect } from "react";
import { Button, Modal, Row, Col, Spinner } from "react-bootstrap";
import AdminExcelHook from "../../hook/admin/AdminExcelHook";
//import ViewSalesProductAdminHook from "../../hook/admin/ViewSalesProductAdminHook";
import AdminOrderItem from "./AdminOrderItem";
import Pagination from "../../Components/Utilities/Pagination";
//import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import DataTableExtensions from "react-data-table-component-extensions";
import { useNavigate } from "react-router-dom";
import notify from "../../hook/useNotification";
import { sendEmail } from "../../redux/actions/cartAction";
import { Helmet } from "react-helmet";
import MultiImageInput from "react-multiple-image-input";

const AdminExcel = () => {
  //console.log('excel');
//   let loggedUser=JSON.parse(localStorage.getItem('user'));
//  let role=loggedUser.role;
  //console.log(loggedUser.role);
  const { t } = useTranslation();

  const [
    onSelectCategory,
    category,
    onSelectSubCategory,
    subCategory,
    onSelectBrand,
    brand,
    webSiteData,
    user,
    onChangeVendor,
    vedorId,
    getAllUser,
    images,
    setImages,
    settingsRes,
    isPress,
    onChangeSetExcelFile,
    handleImageChange,
    handleUploadPhotos,
    handleSubmit,
   
  ] = AdminExcelHook();
  return (
    <div>
      <Row className="justify-content-start ">
        <div className="admin-content-text pb-4 mt-3">{t("import excel")}</div>
        <Col sm="8">
           <div className="admin-content-text pb-4 mt-3">
            1/{t("download excel")}
          </div>
          <a
            className="btn-save d-inline mt-3 justify-content-end btn-save2"
            href="http://shelly-ksa.com/public/uploads/productsShelly.xlsx"
            style={{
              backgroundColor: settingsRes?.data?.primaryColor,
              padding: " 1%",
              "text-decoration": "none",
            }}
          >
          {t("download here")}
          </a>

          

          <div className="admin-content-text pb-4 mt-4" >
          2/{t("fill form")}
          </div>

          <label htmlFor="categoriesProduct" className="d-block mt-1">
            {t("excel information")}
          </label>



          <label htmlFor="categoriesProduct" className="d-block mt-4">
            {t("main classification")}
          </label>
          <select
            id="categoriesProduct"
            name="categories"
            onChange={onSelectCategory}
            className="select input-form-area mt-2 px-2 "
          >
            <option value="0">{t("main classification")}</option>

            {category.data
              ? category?.data.map((el) => {
                  return (
                    <option key={el._id} value={el._id}>
                      {Cookies.get("i18next") === "ar" ? el.nameAr : el.nameEn}
                    </option>
                  );
                })
              : null}
          </select>
          <label htmlFor="subCategoriesProduct" className="d-block mt-2 ">
            {t("Subcategory")}
          </label>

          <select
            id="subCategoriesProduct"
            name="subCat"
            onChange={onSelectSubCategory}
            className="select input-form-area mt-2 px-2 "
          >
            <option value="0">{t("Subcategory")}</option>
            {subCategory
              ? subCategory.map((el) => {
                  return (
                    <option key={el._id} value={el._id}>
                      {Cookies.get("i18next") === "ar" ? el.nameAr : el.nameEn}
                    </option>
                  );
                })
              : null}
          </select>
          <label htmlFor="brand" className="d-block mt-2 ">
            {t("Brand")}
          </label>

          <select
            name="brand"
            onChange={onSelectBrand}
            id="brand"
            className="select input-form-area mt-2 px-2 "
          >
            <option value="">{t("select category")}</option>
            {brand.data
              ? brand.data.map((el) => {
                  return (
                    <option key={el._id} value={el._id}>
                      {Cookies.get("i18next") == "ar" ? el.nameAr : el.nameEn}
                    </option>
                  );
                })
              : null}
          </select>

          {webSiteData.MULTI_VENDOR == "true" && user?.role === "admin" && (
            <>
              <label htmlFor="vendor" className="d-block mt-2 ">
                {t("select vendor name")}
              </label>
              <select
                onChange={onChangeVendor}
                value={vedorId}
                id="vendor"
                className="select input-form-area mt-3 px-2 "
                name="vendors"
              >
                <option value="0">{t("select vendor")}</option>
                {getAllUser
                  ? getAllUser.map((el) => {
                      return (
                        <option key={el.id} value={el._id}>
                          {el.firstname}
                        </option>
                      );
                    })
                  : null}
              </select>
            </>
          )}

           <div className="admin-content-text pb-4 mt-4" >
            3/{t("select sub category")}
          </div>
         
          

          <div className="mt-3">
            <label htmlFor="vendor" className="d-block mt-2 ">
              {t("select excel file")}
            </label>
            <input
              onChange={onChangeSetExcelFile}
              type="file"
              accept=".xls,.xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              style={{ "margin-top": "1%" }}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm="12" className="d-flex justify-content-start mt-4 ">
          <button
            onClick={handleSubmit}
            className="btn-save d-inline mt-3 btn-save2"
            style={{ backgroundColor: settingsRes?.data?.primaryColor }}
            disabled={isPress === true ? true : false}
          >
            <div className="d-flex justify-content-evenly align-items-center ">
              <p
                style={{
                  all: "unset",
                }}
              >
                {t("import")}
              </p>
             
            </div>
          </button>
        </Col>

        <div className="admin-content-text pb-4 mt-4">4/{t("upload photos")}</div>

        <label htmlFor="categoriesProduct" className="d-block mt-1">
            {t("upload photos info")}
        </label>
        <label htmlFor="categoriesProduct" className="d-block">
            {t("upload photos info2")}
        </label>

        <Col sm="8">

       

        <div className="mt-3">

        <input type="file" name="images" id="imgid" className="imgcls" onChange={handleImageChange} accept="image/*" multiple/>
        
            
          </div>

        </Col>

        <Col sm="12" className="d-flex justify-content-start ">
          <button
            onClick={handleUploadPhotos}
            className="btn-save d-inline mt-3 btn-save2 "
            style={{ backgroundColor: settingsRes?.data?.primaryColor }}
            disabled={isPress === true ? true : false}
          >
            <div className="d-flex justify-content-evenly align-items-center">
              <p
                style={{
                  all: "unset",
                }}
              >
                {t("upload photos now")}
              </p>
              
            </div>
          </button>
        </Col>
        
          
      </Row>
      <ToastContainer />
    </div>
  );
};

export default AdminExcel;
