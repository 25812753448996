import { NavLink } from "react-router-dom";
import { FaBars, FaHome, FaLock, FaMoneyBill, FaUser } from "react-icons/fa";
import { MdMessage } from "react-icons/md";
import { BiAnalyse, BiSearch } from "react-icons/bi";
import { BiCog } from "react-icons/bi";
import { BiDollarCircle } from "react-icons/bi";
import { BiGroup } from "react-icons/bi";
import { AiFillHeart, AiTwotoneFileExclamation , AiFillFire } from "react-icons/ai";
import { BsCartCheck } from "react-icons/bs";
import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import SidebarMenu from "./SidebarMenu";
import { useTranslation } from "react-i18next";
import AdminAllOrdersHook from "../../hook/admin/AdminAllOrdersHook";
import { useSelector } from "react-redux";




const SideBar = ({ children }) => {


  const [, , orderData] = AdminAllOrdersHook()

  const settingsRes = useSelector((state) => state.settingsReducers.settings);

  const { t } = useTranslation();
  const routes = [
    {
      path: "/",
      name: t("Main"),
      icon: <FaHome />,
    },
    {
      path: '/admin/profile',
      name: t("profile"),
      icon: <i className="fa-solid fa-user"></i>

    },
    {
      path: "/admin/orders",
      name: <div className="d-flex justify-content-end w-100" >
        <div >
          {t("orders")}
        </div>
        <div
          className="mx-2"
          style={{
            backgroundColor: 'red', borderRadius: "50%", width: "25px",
            height: "25px", textAlign: "center",

          }}>
          {orderData.filter((el) => el.orderStatusId?.key === 1).length}
        </div>

      </div>,
      icon: <AiFillHeart />,
    },
    {
      path: "/admin/alluser",
      name: t("users"),
      icon: <AiFillHeart />,
    },
    {
      path: "/reports",
      name: t("product mangement"),
      icon: <BiCog />,
      exact: true,
      subRoutes: [
        {
          path: "/admin/products",
          name: t("product"),
          icon: <FaUser />,
        },

        {
          path: "/admin/categories",
          name: t("category"),
          icon: <FaMoneyBill />,
        },

        {
          path: "/admin/brands",
          name: t("brand"),
          icon: <FaMoneyBill />,
        },

        {
          path: "/admin/coupons",
          name: t("copon"),
          icon: <FaMoneyBill />,
        },
        {
          path:"/admin/offers",
          name:t("offers"),
          icon: <AiFillFire/> 
        }
        ,
        {
          path:"/admin/excel",
          name:t("excel"),
          icon: <AiFillFire/> 
        }
      ],
    },
    {
      path: "/admin/paymethoudAdmin",
      name: t("payment method"),
      icon: <BiDollarCircle />,
    },
    {
      path: "/admin/shippingMethod",
      name: t("Shipping Methods"),
      icon: <BiDollarCircle />,
    },
    {
      path: "/admin/ads",
      name: t("ads"),
      icon: <BiDollarCircle />,
    },
    {
      path: "/admin/viewslider",
      name: t("image bar"),
      icon: <AiFillHeart />,
    },
    {
      path: "/admin/tickets",
      name: t("contact"),
      icon: <BiGroup />,
    },
    {
      path: "/reports",
      name: t("Reports"),
      icon: <BiCog />,
      exact: true,
      subRoutes: [
        {
          path: "/admin/cartReport",
          name: t("Abandoned Basket Management"),
          icon: <FaUser />,
        },
        {
          path: "/admin/productSalesReport",
          name: t("Sales Report"),
          icon: <FaLock />,
        },
        {
          path: "/admin/googleAnalytics",
          name: t("Google Analytics Report"),
          icon: <FaMoneyBill />,
        },
      ],
    },
    {
      path: "/reports",
      name: t("fixed page"),
      icon: <BiCog />,
      exact: true,
      subRoutes: [
        {
          path: "/admin/terms",
          name: t("Terms & Conditions"),
          icon: <FaUser />,
        },
        {
          path: "/admin/policy",
          name: t("Refund Policy"),
          icon: <FaLock />,
        },
        {
          path: "/admin/about",
          name: t("About Us"),
          icon: <FaMoneyBill />,
        },
      ],
    },
    {
      path: "/settings",
      name: t("Settings"),
      icon: <BiCog />,
      exact: true,
      subRoutes: [
        {
          path: "/admin/settings",
          name: t("Site Settings"),
          icon: <FaUser />,
        },
      ],
    },
  ];
  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => setIsOpen(!isOpen);
  const inputAnimation = {
    hidden: {
      width: 0,
      padding: 0,
      transition: {
        duration: 0.2,
      },
    },
    show: {
      width: "140px",
      padding: "5px 15px",
      transition: {
        duration: 0.2,
      },
    },
  };

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: "auto",
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <>
      <div className="main-container">
        <motion.div
          animate={{
            width: isOpen ? "200px" : "45px",

            transition: {
              duration: 0.5,
              type: "spring",
              damping: 10,
            },
          }}
          className={`sidebarMenu `}
          style={{ backgroundColor: settingsRes?.data?.secondaryColor }}
        >
          <div className="top_section">
            <AnimatePresence>
              {isOpen && (
                <motion.h1
                  variants={showAnimation}
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  className="logoMenu"
                >
                  {t("control panal")}
                </motion.h1>
              )}
            </AnimatePresence>

            <div className="bars">
              <FaBars onClick={toggle} />
            </div>
          </div>
          {/* <div className="search">
            <div className="search_icon">
              <BiSearch />
            </div>
            <AnimatePresence>
              {isOpen && (
                <motion.input
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  variants={inputAnimation}
                  type="text"
                  placeholder="Search"
                />
              )}
            </AnimatePresence>
          </div> */}
          <section className="routes">
            {routes.map((route, index) => {
              if (route.subRoutes) {
                return (
                  <SidebarMenu
                  key={index}
                    setIsOpen={setIsOpen}
                    route={route}
                    showAnimation={showAnimation}
                    isOpen={isOpen}
                  />
                );
              }

              return (
                <NavLink
                  to={route.path}
                  key={index}
                  className="link"
                
                >
                  <div className="icon">{route.icon}</div>
                  <AnimatePresence>
                    {isOpen && (
                      <motion.div
                        variants={showAnimation}
                        initial="hidden"
                        animate="show"
                        exit="hidden"
                        className="link_text"
                      >
                        {route.name}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </NavLink>
              );
            })}
          </section>
        </motion.div>

        <main>{children}</main>
      </div>
    </>
  );
};

export default SideBar;
