import React, { useEffect, useState } from "react";
import { Row, Spinner } from "react-bootstrap";
import ProductCard from "./../Products/ProductCard";
import Pagination from "../Utilities/Pagination";
import CardProductsContainer from "../Products/CardProductsContainer";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { favoriteByUserId } from "../../redux/actions/favoriteActions";
const UserFavoriteProduct = () => {
  const { t } = useTranslation() ;
  var user = "";
  if (localStorage.getItem("user") != null) {
    user = JSON.parse(localStorage.getItem("user"));
  }
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [checkData, setCheckData] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const get = async () => {
      setCheckData(true);
      setLoading(true);
      await dispatch(favoriteByUserId(user._id));
      setLoading(false);
      setCheckData(false);
    };

    get();
  }, []);
  const res = useSelector((state) => state.favoriteReducers.userFavorite);

  useEffect(() => {
    if (loading === false) {
      const products = res.map((el) => el.product);
      setItems(products);
    }
  }, [loading, res]);

  return (
    <div>
      <div className="admin-content-text pb-4">{t("Favorites List")}</div>
      <Row className="justify-content-start">
        {checkData === true ? (

          <Spinner animation="border" />
        ) : items.length <= 0 ? (
          <h6>{t("no favorites products")}</h6>
        ) : (
          <CardProductsContainer products={items} />
        )}
      </Row>
    </div>
  );
};

export default UserFavoriteProduct;
