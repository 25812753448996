import { useEffect, useState } from "react";
import notify from "../useNotification";
import { forgetPasswordEmailCode } from "../../redux/actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ForgetPasswordHook = () => {
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(true);
  const [codeVerify, setCodeVerify] = useState("");
  const clearValue = () => setCodeVerify("");
  const [show, setShow] = useState(false);
  

  const dispatch = useDispatch();
  const navigate = useNavigate()

  const handleCloseModel = () => {
    setShow(false);
  };

  const onChangeValue = (e) => {
    setValue(e.target.value);
  };

  const sendCode = () => {
    if (value.trim() === "") {
      return notify("تاكد من إدخال البريد الإلكتروني او رقم الجوال", "error");
    } else {
      setLoading(true);
      dispatch(forgetPasswordEmailCode({ val: value }));
      setLoading(false);
    }
  };

  const sendEmailCodeResponse = useSelector(
    (state) => state.authReducers.resetPasswordCode
  );


  
  const handlePressed = async () => {
    if (sendEmailCodeResponse.message == codeVerify) {
        // console.log("success")
        navigate('/user/ResetPassword',{state:{email:value}})

    } else {
      notify("الكود الذى ادخلته غير صحيح", "error");
      setCodeVerify("")
    }
  };


  useEffect(() => {
    if (loading === false) {
      if (sendEmailCodeResponse) {
        // console.log("sendEmailCodeResponse");
        // console.log(sendEmailCodeResponse);
        if (sendEmailCodeResponse.status === 400) {
          return notify("تاكد من البريد الإلكتروني او رقم الجوال", "error");
        } else if (sendEmailCodeResponse.success === true) {
          setShow(true);
        }
       
      }
    }
  }, [loading, sendEmailCodeResponse]);

  return [
    value,
    onChangeValue,
    sendCode,
    show,
    handleCloseModel,
    codeVerify,
    setCodeVerify,
    clearValue,
    handlePressed
  ];
};

export default ForgetPasswordHook;
