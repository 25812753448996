import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllOrderByUser,
  getOneOrder,
} from "../../redux/actions/orderAction";
import { getSettings } from "../../redux/actions/settingsAcions";


const GetOrderDetailsHook = (id) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [orderData, setOrderData] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const ref = useRef(null)

  const get = async () => {
    ref.current.continuousStart()
    setLoading(true);
    await dispatch(getOneOrder(id));
    setLoading(false);
  };

  useEffect(() => {
    get();
  }, []);
  const res = useSelector((state) => state.orderReducer.oneOrder);
  const settingsRes = useSelector((state) => state.settingsReducers.settings);

  useEffect(() => {
    if (loading === false) {
      if (res) {
        setOrderData(res);
        setCartItems(res.orderItems);
        ref.current.complete()

      }
    }
  }, [loading]);

  return [orderData, cartItems, settingsRes, ref];
};

export default GetOrderDetailsHook;
