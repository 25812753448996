import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import AdminProducts from "../../Components/Admin/AdminProducts";
import AdminProductSalesReport from "../../Components/Admin/AdminProductSalesReport";
import AdminSideBar from "../../Components/Admin/AdminSideBar";
import SideBar from "../../Components/Admin/SideBar";
import Pagination from "../../Components/Utilities/Pagination";
import ViewProductAdminHook from "../../hook/admin/ViewProductAdminHook";
import ViewSalesProductAdminHook from "../../hook/admin/ViewSalesProductAdminHook";

const AdminProductSalesReportPage = () => {
    const dispatch = useDispatch();

    return (
        <div className="d-flex justify-content-start">
            <div>
                <SideBar />
            </div>
            <div className="p-4" style={{ width: '100%' }}>
                <AdminProductSalesReport />
            </div>
        </div>

    );
};

export default AdminProductSalesReportPage;
