import React from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import add from "../../Images/add.png";
import { useParams } from "react-router-dom";
import MultiImageInput from "react-multiple-image-input";
import { CompactPicker } from "react-color";
import { ToastContainer } from "react-toastify";
import EditProductHook from "../../hook/product/EditProductHook";
import AdminEditSliderHook from "../../hook/admin/AdminEditSliderHook";
import AdminEditAdsHook from "../../hook/admin/AdminEditAdsHook";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const AdminEditAds = () => {
    const { t } = useTranslation()
    const { id } = useParams();
    const [
        title,
        key,
        showPicker,
        color,
        handleChaneComplete,
        handleSubmit,
        onChangeTitle,
        onChangeKey,
        onChangePicker,
        check,
        page,
        onChangePage,
        showSecondPicker,
        secondaryColor,
        handleChaneCompleteSecond,
        onChangeSecondPicker,
        link,
        onChangeLink,
        images,
        setImages,
        link2,
        onChangeLink2
    ] = AdminEditAdsHook(id);
    const settingsRes = useSelector((state) => state.settingsReducers.settings);


    return (
        <div>
            <Row className="justify-content-start ">
                <div className="admin-content-text pb-4"> {t("Ad modification")} - {title}</div>
                <Col sm="6">
                <div className="mt-3" id="images">
                <MultiImageInput
                  images={images}
                  setImages={setImages}
                  theme="light"
                  max={2}
                  // cropConfig={{ crop, ruleOfThirds: true }}
                  allowCrop={false}
                />
              </div>
                    <label htmlFor="title" className="d-block mt-2 "> {t("address")} </label>

                    <input
                        id="title"
                        onChange={onChangeTitle}
                        value={title}
                        type="text"
                        className="input-form d-block mt-2 px-3"
                        placeholder={t("address")}
                    />
                    
                    <label htmlFor="links" className="d-block mt-3"> الرابط </label>
                    <input
                        id="links"
                        onChange={onChangeLink}
                        value={link}
                        type="text"
                        className="input-form d-block mt-2 px-3"
                        placeholder="الرابط"
                    />
                    <label htmlFor="link2" className="d-block mt-2 ">
                    {" "}
                    {t("ads link")}
                    {" 2"}
                  </label>
                  <input
                    id="link2"
                    value={link2}
                    onChange={onChangeLink2}
                    type="text"
                    className="input-form d-block mt-2 px-3"
                    placeholder={t("Link")}
                  />
                    {/* <input
                        onChange={onChangeKey}
                        value={key}
                        type="number"
                        className="input-form d-block mt-3 px-3"
                        placeholder="الرمز"
                    /> */}
                    <label htmlFor="key" className="d-block mt-2 ">{t("Ad place")}</label>

                    <select
                        value={key}
                        onChange={onChangeKey}
                        name="key"
                        id="key"
                        className="select input-form-area mt-2 px-3 "
                    >
                        <option value={0}>{t("code")}</option>
                        <option value={1}>{t("Top")}</option>
                        <option value={2}>{t("Down")}</option>
                    </select>
                    <label htmlFor="page" className="d-block mt-2 "> {t("page")} </label>

                    <input
                        id="page"
                        onChange={onChangePage}
                        value={page}
                        type="text"
                        className="input-form d-block mt-2 px-3"
                        placeholder={t("page")}
                        disabled
                    />

                    <div className="text-form mt-3 ">{t("Main background color")}</div>
                    <div className="mt-1 d-flex">
                        {color && (
                            <div
                                className="color ms-2 border  mt-1"
                                style={{ backgroundColor: color }}
                            ></div>
                        )}
                        <img
                            src={add}
                            alt=""
                            width="30px"
                            height="35px"
                            style={{ cursor: "pointer" }}
                            onClick={onChangePicker}
                        />
                        {showPicker === true ? (
                            <CompactPicker onChangeComplete={handleChaneComplete} />
                        ) : null}
                    </div>
                    <div className="text-form mt-3 ">{t("sub background color")}</div>
                    <div className="mt-1 d-flex">
                        {secondaryColor && (
                            <div
                                className="color ms-2 border  mt-1"
                                style={{ backgroundColor: secondaryColor }}
                            ></div>
                        )}
                        <img
                            src={add}
                            alt=""
                            width="30px"
                            height="35px"
                            style={{ cursor: "pointer" }}
                            onClick={onChangeSecondPicker}
                        />
                        {showSecondPicker === true ? (
                            <CompactPicker onChangeComplete={handleChaneCompleteSecond} />
                        ) : null}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col sm="8" className="d-flex justify-content-end ">
                    {check == true ? (
                        <Spinner animation="border" variant="primary" />
                    ) : (
                        <button onClick={handleSubmit} className="btn-save d-inline mt-2 " style={{ backgroundColor: settingsRes?.data?.primaryColor }}>
                            {t("Modify")}
                        </button>
                    )}
                </Col>
            </Row>
            <ToastContainer />
        </div>
    );
};

export default AdminEditAds;
