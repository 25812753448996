import React, { useEffect, useState } from "react";
import { createBrand } from "../../redux/actions/brandAction";
import { useDispatch, useSelector } from "react-redux";
import notify from "../../hook/useNotification";
import { deleteReviewProduct } from "../../redux/actions/reviewActions";

const DeleteRateHook = (review) => {

  var user = "";
  if (localStorage.getItem("user") != null) {
    user = JSON.parse(localStorage.getItem("user"));
  }

  // let userObj = JSON.parse(localStorage.getItem("user"));


  const dispatch = useDispatch();
  const [isUser, setIsUser] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showDelete, setShowDelete] = useState(false);

  const handleClose = () => setShowDelete(false);
  const handleShow = () => setShowDelete(true);

  useEffect(() => {

    if (review.user._id === user._id) {
      setIsUser(true);
    }
  }, []);
  const handleDelete = async () => {
    setLoading(true);
    await dispatch(deleteReviewProduct(review._id));
    setLoading(false);
    handleClose();
  };

  const res = useSelector((state) => state.reviewReducer.deleteReview);

  useEffect(() => {
    if (loading === false) {
      if (res) {
        if (res.success === true) {
          notify("تم حذف التقييم بنجاح", "success");
          setTimeout(() => {
            window.location.reload(false);
          }, 1000);
        } else {
          notify("هناك مشكله فى عملية المسح", "error");
        }
      }
    }
  }, [loading]);

  return [isUser, handleDelete, handleShow, handleClose, showDelete];
};

export default DeleteRateHook;
