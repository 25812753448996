import React, { useMemo, useEffect, useState } from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import notify from "../../hook/useNotification";
import {
  addUserAddress,
  getUserAddress,
} from "../../redux/actions/userAddressActions";

import { getSettings } from "../../redux/actions/settingsAcions.js"


const AddAddressHook = (type) => {

  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setLatitude(position.coords.latitude);
      setLongitude(position.coords.longitude);
    });

  }, []);

  const [show, setShow] = useState(false);
 


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [name, setName] = useState("");
  const [zone, setZone] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [mobile, setMobile] = useState("");
  const [closeSign, setCloseSign] = useState("");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onChangeName = (e) => {
    e.persist();
    setName(e.target.value);
  };
  const onChangeZone = (e) => {
    e.persist();
    setZone(e.target.value);
  };
  const onChangeCity = (e) => {
    e.persist();
    setCity(e.target.value);
  };
  const onChangeAddress = (e) => {
    e.persist();
    setAddress(e.target.value);
  };
  const onChangeMobile = (e) => {
    e.persist();
    setMobile(e.target.value);
  };
  const onChangeCloseSign = (e) => {
    e.persist();
    setCloseSign(e.target.value);
  };
  const onChangeLatLang = (lat, lang) => {
    setLatitude(lat);
    setLongitude(lang);
  }

  const settingsRes = useSelector((state) => state.settingsReducers.settings);
  const showAddressDetails = settingsRes?.data?.showAddressDetails
  // console.log(showAddressDetails);

  var user = "";
  if (localStorage.getItem("user") != null) {
    user = JSON.parse(localStorage.getItem("user"));
  }
  const res = useSelector((state) => state.userAddressReducer.userAddAddress);

  const onSubmit = async () => {
    if (
      name === "" ||
      zone === "" ||
      city === "" ||
      closeSign === "" ||
      mobile === "" ||
      address === "" ||
      latitude === "" ||
      longitude === ""

    ) {
      // notify("من فضلك اكمل البيانات", "error");
    }
    setLoading(true);
    await dispatch(
      addUserAddress({
        name: name,
        user: user._id,
        city: city,
        zone: zone,
        closeSign: closeSign,
        address: address,
        mobile: mobile,
        latitude: latitude,
        longitude: longitude,
      })
    );
    setLoading(false);
  };

  useEffect(() => {
    if (loading === false) {
      if (res && res.success === true) {
        notify("تم الحفظ بنجاح", "success");
        setName("")
        setAddress("")
        setCity("")
        setMobile("")
        setZone("")
        setCloseSign("")
        setLoading("")
        setShow(false);

        setTimeout(() => {
          if (type === 1) {
            navigate("/user/addresses");
          } else {
            dispatch(getUserAddress(user._id));
            // navigate("/order/paymethoud");
          }
        }, 1000);
      } else {
        notify("فشل في عملية الاضافه", "error");
      }
    }
  }, [loading]);

  return [
    name,
    zone,
    city,
    address,
    mobile,
    closeSign,
    onChangeName,
    onChangeZone,
    onChangeCity,
    onChangeAddress,
    onChangeMobile,
    onChangeCloseSign,
    onSubmit,
    show,
    handleShow,
    handleClose,
    latitude,
    longitude,
    onChangeLatLang,
    showAddressDetails,
      
  ];
};

export default AddAddressHook;
