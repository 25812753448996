import useDeleteData from "../../hooks/useDeleteData";
import { useGetData } from "../../hooks/useGetData";
import { useInsertData } from "../../hooks/useInsertData";
import { useUpdateData } from "../../hooks/useUpdateData";
import {
  ADD_OFFER,
  ADD_PRODUCT_TO_OFFER,
  ALL_OFFERS,
  DELETE_PRODUCT_OFFER_BY_OFFER_DETAIL_ID,
  GET_OFFER_DETAILES_BY_OFFER_ID,
  GET_ONE_OFFER,
  UPDATE_OFFER,
} from "../type";

// get All Offers
export const getAllOffers = (user) => async (dispatch) => {
  try {
    const response = await useGetData(`/api/v1/offers?user=${user}`);
    dispatch({
      type: ALL_OFFERS,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: ALL_OFFERS,
      payload: error.response,
    });
  }
};

//Add Offer
export const addOffer = (body) => async (dispatch) => {
  try {
    const response = await useInsertData("/api/v1/offers", body);
    dispatch({
      type: ADD_OFFER,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: ADD_OFFER,
      payload: error.response,
    });
  }
};

// get One Offer
export const getOneOffer = (id) => async (dispatch) => {
  try {
    const response = await useGetData(`/api/v1/offers/${id}`);
    dispatch({
      type: GET_ONE_OFFER,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: GET_ONE_OFFER,
      payload: error.response,
    });
  }
};

// update One Offer
export const updateOffer = (id, body) => async (dispatch) => {
  try {
    const response = await useUpdateData(`/api/v1/offers/${id}`, body);
    dispatch({
      type: UPDATE_OFFER,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_OFFER,
      payload: error.response,
    });
  }
};

// get all offer Details By Offer id
export const getOfferDetailsByOfferId = (id) => async (dispatch) => {
  try {
    const response = await useGetData(`/api/v1/offerDetails/offer/${id}`);
    dispatch({
      type: GET_OFFER_DETAILES_BY_OFFER_ID,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: GET_OFFER_DETAILES_BY_OFFER_ID,
      payload: error.response,
    });
  }
};

// ************ delete the offer from the product based Offer Detail Id *************************
export const deleteTheProductOffer = (offerDetailId) => async (dispatch) => {
  try {
    const response = await useDeleteData(
      `/api/v1/offerDetails/${offerDetailId}`
    );
    dispatch({
      type: DELETE_PRODUCT_OFFER_BY_OFFER_DETAIL_ID,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: DELETE_PRODUCT_OFFER_BY_OFFER_DETAIL_ID,
      payload: error.response,
    });
  }
};

// ********** add Product To Offer ****************
export const addProductToOffer = (body) => async (dispatch) => {
  try {
    
    const response = await useInsertData(`/api/v1/offerDetails`,body)
    dispatch({
      type:ADD_PRODUCT_TO_OFFER,
      payload:response
    })

  } catch (error) {
    
    dispatch({
      type:ADD_PRODUCT_TO_OFFER,
      payload: error.response
    })

  }
}