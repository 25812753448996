import {
  ADD_TO_CART,
  GET_USER_CART_ITEMS,
  DELETE_ALL_CART_ITEMS,
  REMOVE_ONE_ITEM,
  UPDATE_ITEM_CART,
  APPLY_COUPON,
  ADMIN_ALL_CARTS,
  SEND_EMAIL
} from "../type";
import { useInsertData } from "../../hooks/useInsertData";
import { useGetData, useGetDataToken } from "../../hooks/useGetData";
import useDeleteData from "../../hooks/useDeleteData";
import { useUpdateData } from "../../hooks/useUpdateData";

export const addProductToCart = (body) => async (dispatch) => {
  try {
    const response = await useInsertData(`/api/v1/cart/website`, body);
    dispatch({
      type: ADD_TO_CART,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: ADD_TO_CART,
      payload: error,
    });
  }
};

export const getAllCartItems = (id) => async (dispatch) => {
  try {
    const response = await useGetDataToken(`/api/v1/cart/website/${id}`);
    dispatch({
      type: GET_USER_CART_ITEMS,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: GET_USER_CART_ITEMS,
      payload: error,
    });
  }
};

export const removeAllCartItems = (id) => async (dispatch) => {
  try {
    const response = await useDeleteData(`/api/v1/cart/remove/${id}`);
    dispatch({
      type: DELETE_ALL_CART_ITEMS,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: DELETE_ALL_CART_ITEMS,
      payload: error,
    });
  }
};

export const removeOneItem = (id) => async (dispatch) => {
  try {
    const response = await useDeleteData(`/api/v1/cart/${id}`);
    dispatch({
      type: REMOVE_ONE_ITEM,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: REMOVE_ONE_ITEM,
      payload: error,
    });
  }
};

export const updateItemCart = (id, body) => async (dispatch) => {
  try {
    const response = await useUpdateData(`/api/v1/cart/webiste/${id}`, body);
    dispatch({
      type: UPDATE_ITEM_CART,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_ITEM_CART,
      payload: error,
    });
  }
};

// apply coupon
export const applyCoupon = (title, body) => async (dispatch) => {
  try {
    const response = await useUpdateData(
      `/api/v1/coupons/website/${title}`,
      body
    );
    dispatch({
      type: APPLY_COUPON,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: APPLY_COUPON,
      payload: error,
    });
  }
};


// admin all cart 
export const adminAllCart = () => async (dispatch) => {
  try {
    const response = await useGetDataToken(`/api/v1/cart`);
    dispatch({
      type: ADMIN_ALL_CARTS,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_ALL_CARTS,
      payload: error,
    });
  }
};


export const sendEmail = (body) => async (dispatch) => {
  try {
    const response = await useInsertData(`/api/v1/cart/website/sendEmail`, body);
    dispatch({
      type: SEND_EMAIL,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: SEND_EMAIL,
      payload: error,
    });
  }
};
