import Cookies from "js-cookie";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import OfferDetailsHook from "../../../hook/offers/offer-Details/OfferDetailsHook";
import AddButton from "../../Utilities/AddButton";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import DataTable from "react-data-table-component";
import { Helmet } from "react-helmet";
import { ToastContainer } from "react-toastify";
import add from "../../../Images/add.png";
import LoadingSpinner from "../../Utilities/LoadingSpinner";

const OfferDetails = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const [
    offerDetailsResponse,
    settingsRes,
    deleteModal,
    showDeleteModal,
    closeDeleteModal,
    DeleteOfferProductDetail,
    ProductOfferInfoModal,
    showProductOfferInfoModal,
    closeProductOfferInfoModal,
    showProductsModal,
    onChangeSelectCategory,
    AllCategoryResponse,
    onChangeSelectedSubCategory,
    subCategoryByCat,
    onSelectBrand,
    allBrandResponse,
    user,
    webSiteData,
    onChangeVendor,
    getAllUser,
    ProductsModal,
    allProductsFilteredResponse,
    closeProductsModal,
    onSubmitSelectedProducts,
    categoryId,
    selectedsubId,
    brandId,
    vedorId,
    handleRowSelected,
    offerValue,
    offerType,
    onChangeOfferValue,
    onChangeOfferType,
    loadingSpinnerProduct,
    loadingSpinnerOfferDetails
  ] = OfferDetailsHook(id);

  
  const filterProducts = allProductsFilteredResponse.filter(
    (el) => el.priceOffer === 0
    );
  

// thsi colums of the offer Details product name , offer value , offer type 
  let columns = [
    {
      name: t("product name"),
      selector: (row) =>
        Cookies.get("i18next") === "ar"
          ? row?.product?.nameAr
          : row?.product?.nameEn,
      sortable: true,
    },
    {
      name: t("Offer Name"),
      selector: (row) =>
        Cookies.get("i18next") === "ar"
          ? row?.offerId?.nameAr
          : row?.offerId?.nameEn,
      sortable: true,
    },
    {
      name: t("Offer Type"),
      selector: (row) => (row.type === 1 ? t("value") : t("percent")),
      sortable: true,
    },
    {
      name: t("Offer Value"),
      selector: (row) =>(row.type === 1 ? row["value"] : `${row.value} % `),
      sortable: true,
    },
    // {
    //   name: t("Modification"),
    //   cell: (row) => (
    //     <Button
    //       variant="secondary"
    //       onClick={() => clickHandler(row._id)}
    //       id={row._id}
    //     >
    //       {t("Modify")}
    //       <i className="fa fa-pencil-square-o mx-1" />
    //     </Button>
    //   ),
    //   ignoreRowClick: true,
    //   allowOverflow: true,
    //   button: true,
    // },
    {
      name: t("delete"),
      cell: (row) => (
        <Button
          variant="danger"
          onClick={() => showDeleteModal(row._id)}
          id={row._id}
        >
          {t("delete")}
          <i className="fa fa-trash mx-1" />
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const tableData = {
    columns,
    data: offerDetailsResponse,
  };

  // this colums of P roducts That show to modal to admin or vendor select one or more product
  columns = [
    {
      name: t("image"),
      cell: (row) => (
        <img
          src={row.image}
          width="50px"
          height="50px"
          style={{ padding: "3px", objectFit: "contain" }}
        />
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: t("product name"),

      selector: (row) =>
        Cookies.get("i18next") === "ar" ? row.nameAr : row.nameEn,
      sortable: true,
    },

    {
      name: t("category"),

      selector: (row) =>
        Cookies.get("i18next") === "ar"
          ? row.category?.nameAr
          : row.category.nameEn,
      sortable: true,
    },
    {
      name: t("Subcategory"),

      selector: (row) =>
        Cookies.get("i18next") === "ar"
          ? row.subCategory.nameAr
          : row.subCategory.nameEn,
      sortable: true,
    },

    {
      name: t("price"),
      selector: (row) => row.price1,
      sortable: true,
    },
  ];

  const tableDataProductsModal = {
    columns,
    data: filterProducts,
  };

  return (
    <div>
      <Helmet>
        <title>
          {` ${t("Offer details")} -  ${
            Cookies.get("i18next") == "ar"
              ? settingsRes?.data?.titleAr
              : settingsRes?.data?.titleEn
          }`}{" "}
        </title>
      </Helmet>

      {/********** Modal To Delete The Offer From The Product *****************/}
      <Modal show={deleteModal} onHide={closeDeleteModal}>
        <Modal.Header>
          <Modal.Title>
            <div className="font">{t("Confirm deletion")} </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="font">
            {t("Are you sure about the deletion process?")}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="font"
            variant="secondary"
            onClick={closeDeleteModal}
          >
            {t("retreat")}
          </Button>
          <Button
            className="font"
            variant="danger"
            onClick={DeleteOfferProductDetail}
          >
            {t("delete")}
          </Button>
        </Modal.Footer>
      </Modal>
      {/************************************************************************/}

      {/***************  Modal to Show Product Offer Info category , sub , brand , vendor ******************/}
      <Modal show={ProductOfferInfoModal} onHide={closeProductOfferInfoModal}>
        <Modal.Header>
          <Modal.Title>
            <div className="font">
              {t("Define some information about the products you want to show")}{" "}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "400px" }}>
          <label htmlFor="categoriesProduct" className="d-block mt-2 ">
            {t("main classification")}
          </label>
          <select
            id="categoriesProduct"
            name="categories"
            value={categoryId}
            onChange={onChangeSelectCategory}
            className="select input-form-area mt-2 px-2 "
          >
            <option value="0">{t("main classification")}</option>

            {AllCategoryResponse.data
              ? AllCategoryResponse?.data.map((el) => {
                  return (
                    <option key={el._id} value={el._id}>
                      {Cookies.get("i18next") === "ar" ? el.nameAr : el.nameEn}
                    </option>
                  );
                })
              : null}
          </select>

          <label htmlFor="subCategoriesProduct" className="d-block mt-2 ">
            {t("Subcategory")}
          </label>

          <select
            id="subCategoriesProduct"
            name="subCat"
            value={selectedsubId}
            onChange={onChangeSelectedSubCategory}
            className="select input-form-area mt-2 px-2 "
          >
            <option value="0">{t("Subcategory")}</option>
            {subCategoryByCat
              ? subCategoryByCat.map((el) => {
                  return (
                    <option key={el._id} value={el._id}>
                      {Cookies.get("i18next") == "ar" ? el.nameAr : el.nameEn}
                    </option>
                  );
                })
              : null}
          </select>

          <label htmlFor="brand" className="d-block mt-2 ">
            {t("Brand")}
          </label>

          <select
            name="brand"
            onChange={onSelectBrand}
            id="brand"
            value={brandId}
            className="select input-form-area mt-2 px-2 "
          >
            <option value="">{t("select category")}</option>
            {allBrandResponse.data
              ? allBrandResponse.data.map((el) => {
                  return (
                    <option key={el._id} value={el._id}>
                      {Cookies.get("i18next") == "ar" ? el.nameAr : el.nameEn}
                    </option>
                  );
                })
              : null}
          </select>

          {webSiteData.MULTI_VENDOR == "true" && user?.role === "admin" && (
            <>
              <label htmlFor="vendor" className="d-block mt-2 ">
                {t("select vendor")}
              </label>
              <select
                onChange={onChangeVendor}
                id="vendor"
                value={vedorId}
                className="select input-form-area mt-3 px-2 "
                name="vendors"
              >
                <option value="0">{t("select vendor")}</option>
                {getAllUser
                  ? getAllUser.map((el) => {
                      return (
                        <option key={el._id} value={el._id}>
                          {el.firstname}
                        </option>
                      );
                    })
                  : null}
              </select>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="font"
            variant="secondary"
            onClick={closeProductOfferInfoModal}
          >
            {t("retreat")}
          </Button>
          <Button className="font" variant="danger" onClick={showProductsModal}>
            {t("show Products")}
          </Button>
        </Modal.Footer>
      </Modal>

      {/*************************************************************************************************/}

      {/***************  Modal to Show Product mODAL tO sELECT ******************/}
      <div className="showProducts">
        <Modal
          show={ProductsModal}
          onHide={closeProductsModal}
          style={{ width: "641px !important" }}
        >
          <Modal.Header>
            <Modal.Title>
              <div className="font">
                {t("Select the products you want to add to the offer")}{" "}
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          {
            loadingSpinnerProduct === true ?(
<LoadingSpinner/>
            ):(
              <div>
              <DataTableExtensions
                {...tableDataProductsModal}
                filterPlaceholder={t("search here")}
              >
                <DataTable
                  noHeader
                  defaultSortAsc={false}
                  pagination
                  highlightOnHover
                  paginationPerPage={5}
                  selectableRows
                  onSelectedRowsChange={handleRowSelected}
                />
              </DataTableExtensions>
            </div>
            )
          }
           

            <div className="d-flex justify-content-between mb-4 ">
              <div>
                <label htmlFor="couponValue" className="d-block  ">
                  {" "}
                  {t("discount value")}{" "}
                </label>
                <input
                  id="couponValue"
                  value={offerValue}
                  onChange={onChangeOfferValue}
                  type="number"
                  className="input-form d-block  px-3 mt-2"
                  placeholder={t("discount value")}
                />
              </div>
              <div className="mb-2">
                <label htmlFor="type" className="d-block  ">
                  {" "}
                  {t("discound value type")}{" "}
                </label>
                <select
                  value={offerType}
                  onChange={onChangeOfferType}
                  name="type"
                  id="type"
                  className="select input-form-area   px-3 mt-2"
                >
                  <option value="0">{t("discound value type")}</option>
                  <option value="1">{t("value")}</option>
                  <option value="2">{t("percent")}</option>
                </select>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="font my-3"
              variant="secondary"
              onClick={closeProductsModal}
            >
              {t("retreat")}
            </Button>

            {filterProducts.length !== 0? (
              <Button
                className="font"
                variant="danger"
                onClick={onSubmitSelectedProducts}
              >
                {t("Add products")}
              </Button>
            ) : null}
          </Modal.Footer>
        </Modal>
      </div>

      {/*************************************************************************************************/}

      <div className="d-flex justify-content-between">
        <div className="admin-content-text">{t("Offers Management")} </div>
        <Button
          style={{
            backgroundColor: settingsRes?.data?.primaryColor,
            borderRadius: "6px",
            borderColor: "greenyellow",
          }}
          variant="secondary"
          onClick={showProductOfferInfoModal}
        >
          {t("Add products To Offer")}
          <img
            src={add}
            alt=""
            width="30px"
            height="35px"
            style={{
              cursor: "pointer",
              marginRight: "8px",
              borderColor: "black",
            }}
          />
        </Button>
      </div>
{
  loadingSpinnerOfferDetails === true?(
<LoadingSpinner/>
  ):(
    <DataTableExtensions {...tableData} filterPlaceholder={t("search here")}>
    <DataTable
      noHeader
      defaultSortAsc={false}
      pagination
      highlightOnHover
    />
  </DataTableExtensions>
  )
}
   

      <ToastContainer />
    </div>
  );
};

export default OfferDetails;
