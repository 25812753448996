import React, { useEffect, useState } from "react";
import { Button, Container, Modal, Row, Spinner } from "react-bootstrap";
import DataTable from "react-data-table-component";
import ViewAllUsersHook from "../../hook/admin/ViewAllUsersHook";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import AdminEditUserHook from "../../hook/admin/AdminEditUserHook";
import { updateUser } from "../../redux/actions/usersActions";
import { useDispatch, useSelector } from "react-redux";
import notify from "../../hook/useNotification";
import { ToastContainer } from "react-toastify";
import add from "../../Images/add.png";
import { useNavigate } from "react-router-dom";
import AddButton from "../Utilities/AddButton";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

const AdminAllUsers = () => {
  const { t } = useTranslation() ;
  const [users, clickHandler, webSiteData , handleShowFun , showReviews , settings] = ViewAllUsersHook();
  const navigate = useNavigate();

  useEffect(() => {
    if (webSiteData.MULTI_VENDOR == "true") {
      columns.unshift({
        name:t("user type"),
        selector: "role",
        sortable: true,
      })
    }
  }, [users])

  let columns = [
    {
      name: t("first name"),
      selector: "firstname",
      sortable: true,
    },
    {
      name: t("last name"),
      selector: "lastname",
      sortable: true,
    },
    {
      name: t("phone"),
      selector: "mobile",
      sortable: true,
    },
    {
      name: t("Wallet"),
      cell: (row) => (
        <view
          style={{ cursor: "pointer" }}
          variant="secondary"
          onClick={() => navigate(`/admin/userTransactions/${row._id}`)}
          id={row._id}
        >
          {t("press here")}
          <i className="fa fa-folder-open-o mx-1" />
        </view>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
    },
    {
      name: t("email"),
      selector: "email",
      sortable: true,
    },
    {
      name: t("Modify"),
      cell: (row) => (
        <Button
          variant="secondary"
          onClick={() => clickHandler(row._id)}
          id={row._id}
        >
          {t("Modify")}
          <i className="fa fa-pencil-square-o mx-1" />
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: t("the status"),
      cell: (row) =>
        row.status == true ? (
          <Button
            variant="danger"
            onClick={() => {
              handleShowFun(row._id, false);
            }}
            id={row._id}
          >
            {t("block")}
            <i className="fa fa-lock mx-1" />
          </Button>
        ) : (
          <Button
            variant="success"
            onClick={() => {
              handleShowFun(row._id, true);
            }}
            id={row._id}
          >
           {t("active")}
            <i className="fa fa-unlock mx-1" />
          </Button>
        ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const tableData = {
    columns,
    data: users,
  };

  return (
    <div>

    <Helmet>
    <title>{ ` ${t("users")} -  ${Cookies.get("i18next")==="ar"?settings.data?.titleAr:settings.data?.titleEn}` } </title>
    </Helmet>

      <div className="d-flex justify-content-between">
        <div className="admin-content-text">{t("User Management")} </div>
        <AddButton title={t("add user")} navigateTo='/admin/addUser' />
     
      </div>

      <DataTableExtensions {...tableData} filterPlaceholder={t("search here")}>
        <DataTable
          noHeader
          defaultSortAsc={false}
          pagination
          highlightOnHover
        />
      </DataTableExtensions>
      <ToastContainer />
    </div>
  );
};
export default AdminAllUsers;

