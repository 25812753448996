import { Row, Col, Tabs, Tab, Button, Modal } from "react-bootstrap";
import React from "react";
import { ToastContainer } from "react-toastify";
import AdminSettingsHook from "../../hook/admin/AdminSettingsHook";
import { t } from "i18next";
import Cookies from "js-cookie";
import { Helmet } from "react-helmet";
import add from "../../Images/add.png";
import { CompactPicker } from "react-color";
import { useSelector } from "react-redux";
import { BsArrowDownCircleFill } from "react-icons/bs";
import { MdBackup } from "react-icons/md";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { FcDownload, FcDataBackup } from "react-icons/fc";

const AdminSettings = () => {
  const [
    facebook,
    instagram,
    twitter,
    phone,
    onChangeFacebook,
    onChangeInstagram,
    onChangeTwitter,
    onChangePhone,
    onSubmit,
    phone2,
    onChangePhone2,
    phone3,
    onChangePhone3,
    email,
    onChangeEmail,
    address,
    onChangeAddress,
    googleAnalyticsURL,
    onChangeGoogleAnalyticsURL,

    showCategory,
    onChangeShowCategory,
    showBrand,
    onChangeShowBrand,
    vatNumber,
    onChangeVatNumber,
    vatValue,
    onChangeVatValue,
    commercialRegister,
    onChangeCommercialRegister,
    titleAr,
    titleEn,
    onChangeTitleAr,
    onChangeTitleEn,
    showReviews,
    onChangeshowReviews,

    showFeaturedProducts,
    onChangeShowFeaturedProducts,


    showAddressDetail,
    onChangeShowAddressDetail,
    descriptionSite,
    keywordsSite,
    onChangeDescriptionSite,
    onChangeKeywordsSite,
    blockSite,
    onChangeBlockSite,
    showPicker,
    colors,
    handleChaneComplete,
    onChangePicker,
    img,
    onImageChange,
    seccolors,
    onChangePickerseccolors,
    handleChaneCompleteseccolors,
    showPickerseccolors,
    downloadBackups,
    backupLogsResponse,
    deleteModal,
    closeDeleteModal,
    showDeleteModal,
    backupLogDelete,
    downloadBackup,
    showRestoreDataModal,
    restoreDataModal,
    closeRestoreDataModel,
    restoreDataBase,
    archiveFile,
    onChangeArchiveFile,
    onSubmitUploadFile,
    loadingRestore,
    loadingExport,
    loadingRestoreLog,
    showNavbarCategory,
    onChangeShowNavCategoryHeader,
    googleMapLink,
onChangeGoogleMapLink,
hideProductDescriptionTabs,
onChangeHideProductDescriptionTabs
  ] = AdminSettingsHook();

  let columns = [
    {
      name: t("Serial"),
      selector: (row) => row["index"],
      sortable: true,
    },
    {
      name: t("name"),
      selector: (row) => row["backupFileName"],
      sortable: true,
    },
    {
      name: t("date"),
      selector: (row) =>
        `${new Date(row?.createdAt)?.getFullYear()}-${new Date(row?.createdAt).getMonth() + 1
        }-${new Date(row?.createdAt).getDate()}`,
      sortable: true,
    },
    {
      name: t("Time"),
      selector: (row) =>
        `${new Date(row?.createdAt).getHours()}:${new Date(
          row?.createdAt
        ).getMinutes()}:${new Date(row?.createdAt).getSeconds()}`,
      sortable: true,
    },
    {
      name: t("Version Number"),
      selector: (row) => row["versionNumber"],
      sortable: true,
    },
    {
      name: t("Restore"),
      cell: (row) => (
        <Button
          disabled={loadingRestoreLog}
          className="download-btn"
          variant="info"
          onClick={() => showRestoreDataModal(row)}
          id={row._id}
        >
          {t("Restore")}
          <FcDataBackup style={{ marginRight: '2px' }} />
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: t("delete"),
      cell: (row) => (
        <Button
          variant="danger"
          className="download-btn"
          onClick={() => showDeleteModal(row._id)}
          id={row._id}
        >
          {t("delete")}
          <i className="fa fa-trash mx-1" />
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: t("Download"),
      cell: (row) => (
        <Button
          variant="success"
          className="download-btn"

          onClick={() => downloadBackup(row)}
          id={row._id}
        >
          {t("Download")}
          <FcDownload style={{ fontSize: "20px", marginRight: "2px" }} />
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const tableData = {
    columns,
    data: backupLogsResponse,
  };

  const settingsRes = useSelector((state) => state.settingsReducers.settings);
  // console.log('settingsRes')
  // console.log(settingsRes)
  return (
    <div>
      {/********** Modal To Delete The  backup from database *****************/}
      <Modal show={deleteModal} onHide={closeDeleteModal}>
        <Modal.Header>
          <Modal.Title>
            <div className="font">{t("Confirm deletion")} </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="font">
            {t("Are you sure about the deletion process?")}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="font"
            variant="secondary"
            onClick={closeDeleteModal}
          >
            {t("retreat")}
          </Button>
          <Button className="font" variant="danger" onClick={backupLogDelete}>
            {t("delete")}
          </Button>
        </Modal.Footer>
      </Modal>

      {/********** Modal To Restore The dataBase  *****************/}
      <Modal show={restoreDataModal} onHide={closeRestoreDataModel}>
        <Modal.Header>
          <Modal.Title>
            <div className="font">{t("Confirm data recovery")} </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="font">
            {t("Are you sure about the data recovery process?")}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="font"
            variant="secondary"
            onClick={closeRestoreDataModel}
          >
            {t("retreat")}
          </Button>
          <Button className="font" variant="primary" onClick={restoreDataBase}>
            {t("Restore")}
            <FcDataBackup style={{ marginRight: '2px' }} />

          </Button>
        </Modal.Footer>
      </Modal>

      <Helmet>
        <title>
          {`${t("settings")} -  ${Cookies.get("i18next") === "ar" ? titleAr : titleAr
            }`}{" "}
        </title>
      </Helmet>
      <Row className="justify-content-start ">
        <div className="admin-content-text pb-4">{t("Edit setup")}</div>

        <Tabs
          defaultActiveKey="home"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab
            eventKey="home"
            title={t("Location Data")}
            style={{ backgroundColor: "white" }}
          >
            <label htmlFor="titleAr" className="d-block mt-2 ">
              {t("address in arabic")}{" "}
            </label>
            <input
              id="titleAr"
              value={titleAr}
              onChange={onChangeTitleAr}
              type="text"
              className="input-form d-block mt-2 px-3"
              placeholder={t("address")}
            />
            <label htmlFor="titleEn" className="d-block mt-2 ">
              {t("address in english")}{" "}
            </label>

            <input
              id="titleEn"
              value={titleEn}
              onChange={onChangeTitleEn}
              type="text"
              className="input-form d-block mt-2 px-3"
              placeholder="Title"
            />

            <label htmlFor="address" className="d-block mt-2 ">
              {" "}
              {t("Address in detail")}{" "}
            </label>
            <input
              id="address"
              value={address}
              onChange={onChangeAddress}
              type="text"
              className="input-form d-block mt-2 px-3"
              placeholder={t("Address in detail")}
            />

            <label htmlFor="commercialRegister" className="d-block mt-2 ">
              {" "}
              {t("Commercial Registration Number")}{" "}
            </label>

            <input
              id="commercialRegister"
              value={commercialRegister}
              onChange={onChangeCommercialRegister}
              type="text"
              className="input-form d-block mt-2 px-3"
              placeholder={t("Commercial Registration Number")}
            />

            <label htmlFor="googleLink" className="d-block mt-2 ">
            {" "}
            {t("Location link")}{" "}
          </label>
          <input
            id="googleLink"
            value={googleMapLink}
            onChange={onChangeGoogleMapLink}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("Location link")}
          />

          </Tab>

          <Tab
            eventKey="SEO"
            title="  SEO"
            style={{ backgroundColor: "white" }}
          >
            <label htmlFor="descriptionSite" className="d-block mt-2 ">
              {" "}
              {t("wepsite Description")}{" "}
            </label>

            <textarea
              onChange={onChangeDescriptionSite}
              value={descriptionSite}
              className="input-form-area p-2 mt-2"
              rows="1"
              cols="50"
              id="descriptionSite"
              placeholder={t("wepsite Description")}
            />

            <label htmlFor="keywordsSite" className="d-block mt-2 ">
              {" "}
              {t("Keywords for the site")}{" "}
            </label>

            <textarea
              onChange={onChangeKeywordsSite}
              value={keywordsSite}
              className="input-form-area p-2 mt-2"
              rows="1"
              cols="50"
              id="keywordsSite"
              placeholder={t("Keywords for the site")}
            />
          </Tab>

          <Tab
            eventKey="block"
            title="  Block Code"
            style={{ backgroundColor: "white" }}
          >
            <label htmlFor="blockSite" className="d-block mt-2 ">
              {" "}
              {t("Add a main block to the site")}{" "}
            </label>

            <textarea
              onChange={onChangeBlockSite}
              value={blockSite}
              className="input-form-area p-2 mt-2"
              rows="5"
              cols="50"
              id="blockSite"
              placeholder={t("Add a main block to the site")}
            />
          </Tab>

          <Tab
            eventKey="social"
            title={t("contact")}
            style={{ backgroundColor: "white" }}
          >
            <label htmlFor="face" className="d-block mt-2 ">
              {" "}
              {t("Facebook")}{" "}
            </label>

            <input
              id="face"
              value={facebook}
              onChange={onChangeFacebook}
              type="text"
              className="input-form d-block mt-2 px-3"
              placeholder={t("Facebook")}
            />
            <label htmlFor="instgram" className="d-block mt-2 ">
              {"instgram"}{" "}
            </label>
            <input
              id="instgram"
              value={instagram}
              onChange={onChangeInstagram}
              type="text"
              className="input-form d-block mt-2 px-3"
              placeholder={"instgram"}
            />
            <label htmlFor="twitter" className="d-block mt-2 ">
              {t("twitter")}{" "}
            </label>
            <input
              id="twitter"
              value={twitter}
              onChange={onChangeTwitter}
              type="text"
              className="input-form d-block mt-2 px-3"
              placeholder={t("twitter")}
            />
            <label htmlFor="phone1" className="d-block mt-2 ">
              {t("Mobile Number 1")}{" "}
            </label>

            <input
              id="phone1"
              value={phone}
              onChange={onChangePhone}
              type="text"
              className="input-form d-block mt-2 px-3"
              placeholder={t("phone number")}
            />
            <label htmlFor="phone2" className="d-block mt-2 ">
              {t("Mobile Number 2")}{" "}
            </label>
            <input
              id="phone2"
              value={phone2}
              onChange={onChangePhone2}
              type="text"
              className="input-form d-block mt-2 px-3"
              placeholder={t("phone number")}
            />
            <label htmlFor="phone3" className="d-block mt-2 ">
              {t("Mobile Number 3")}{" "}
            </label>
            <input
              id="phone3"
              value={phone3}
              onChange={onChangePhone3}
              type="text"
              className="input-form d-block mt-2 px-3"
              placeholder={t("phone number")}
            />
            <label htmlFor="email" className="d-block mt-2 ">
              {" "}
              {t("email")}
            </label>
            <input
              id="email"
              value={email}
              onChange={onChangeEmail}
              type="text"
              className="input-form d-block mt-2 px-3"
              placeholder={t("email")}
            />
          </Tab>

          <Tab
            eventKey="googleAnalyticsURL"
            title={t("Google Analytics link")}
            style={{ backgroundColor: "white" }}
          >
            <label htmlFor="google" className="d-block mt-2 ">
              {" "}
              {t("Google Analytics link")}{" "}
            </label>
            <input
              id="google"
              value={googleAnalyticsURL}
              onChange={onChangeGoogleAnalyticsURL}
              type="text"
              className="input-form d-block mt-2 px-3"
              placeholder={t("Google Analytics link")}
            />
          </Tab>

          <Tab
            eventKey="control"
            title={t("Site Control")}
            style={{ backgroundColor: "white" }}
          >
            <div className="text-form pb-2">{t("websiteLogo")}</div>
            <div>
              <label htmlFor="upload-photo">
                <img
                  src={img}
                  alt="fzx"
                  height="100px"
                  width="120px"
                  style={{ cursor: "pointer" }}
                />
              </label>
              <input
                type="file"
                onChange={onImageChange}
                name="photo"
                id="upload-photo"
              />
            </div>
            <div className="d-flex justify-content-start">
              <div className="text-form mt-3 mx-2"> اللون الرئيسي</div>
              <div className="mt-1 d-flex">
                {colors && (
                  <div
                    className="color ms-2 border  mt-1 mx-2"
                    style={{ backgroundColor: colors }}
                  ></div>
                )}
                <img
                  src={add}
                  alt=""
                  width="30px"
                  height="35px"
                  style={{ cursor: "pointer" }}
                  onClick={onChangePicker}
                />

                {showPicker === true ? (
                  <CompactPicker onChangeComplete={handleChaneComplete} />
                ) : null}
              </div>

              <div className="text-form mt-3 mx-2"> اللون الفرعى</div>
              <div className="mt-1 d-flex">
                {seccolors && (
                  <div
                    className="color ms-2 border  mt-1 mx-2"
                    style={{ backgroundColor: seccolors }}
                  ></div>
                )}
                <img
                  src={add}
                  alt=""
                  width="30px"
                  height="35px"
                  style={{ cursor: "pointer" }}
                  onClick={onChangePickerseccolors}
                />

                {showPickerseccolors === true ? (
                  <CompactPicker
                    onChangeComplete={handleChaneCompleteseccolors}
                  />
                ) : null}
              </div>
            </div>

            <label htmlFor="status" className="d-block mt-2 ">
              {" "}
              {t("Show brands")}{" "}
            </label>
            <select
              value={showBrand}
              onChange={onChangeShowBrand}
              name="status"
              id="status"
              className="select input-form-area mt-2 px-2 "
            >
              <option value="0">{t("Show brands")}</option>
              <option value="true">{t("active")}</option>
              <option value="false">{t("not active")}</option>
            </select>
            <label htmlFor="statuscategory" className="d-block mt-2 ">
              {" "}
              {t("Show labels")}{" "}
            </label>
            <select
              value={showCategory}
              onChange={onChangeShowCategory}
              name="status"
              id="statuscategory"
              className="select input-form-area mt-2 px-2 "
            >
              <option value="0">{t("Show labels")}</option>
              <option value="true">{t("active")}</option>
              <option value="false">{t("not active")}</option>
            </select>
            <label htmlFor="statusReviews" className="d-block mt-2 ">
              {" "}
              {t("Show ratings")}{" "}
            </label>
            <select
              value={showReviews}
              onChange={onChangeshowReviews}
              name="status"
              id="statusReviews"
              className="select input-form-area mt-2 px-2 "
            >
              <option value="0"> {t("Show ratings")}</option>
              <option value="true">{t("active")}</option>
              <option value="false">{t("not active")}</option>
            </select>

            <label htmlFor="status" className="d-block mt-2 ">
            {" "}
            {t("Show Navbar Categories")}{" "}
          </label>
          <select
            value={showNavbarCategory}
            onChange={onChangeShowNavCategoryHeader}
            name="status"
            id="status"
            className="select input-form-area mt-2 px-2 "
          >
            <option value="0">{t("Show brands")}</option>
            <option value="true">{t("active")}</option>
            <option value="false">{t("not active")}</option>
          </select>


            <label htmlFor="showFeaturedProducts" className="d-block mt-2 ">
              {" "}
              {t("Show Featured Products")}
            </label>

            <select
              value={showFeaturedProducts}
              onChange={onChangeShowFeaturedProducts}
              name="status"
              id="showFeaturedProducts"
              className="select input-form-area mt-2 px-2 "
            >
              <option value="0"> {t("Show Featured Products")}</option>
              <option value="true">{t("active")}</option>
              <option value="false">{t("not active")}</option>
            </select>
            <label htmlFor="showAddressDetail" className="d-block mt-2 ">
              {" "}
              {t("Show title details")}{" "}
            </label>
            <select
              value={showAddressDetail}
              onChange={onChangeShowAddressDetail}
              name="status"
              id="showAddressDetail"
              className="select input-form-area mt-2 px-2 "
            >
              <option value="0">{t("Show title details")}</option>
              <option value="true">{t("active")}</option>
              <option value="false">{t("not active")}</option>
            </select>

            <label htmlFor="hideProductDescriptionTabs" className="d-block mt-2 ">
            {" "}
            {t("Hide Product Description Tabs")}
          </label>

          <select
            value={hideProductDescriptionTabs}
            onChange={onChangeHideProductDescriptionTabs}
            name="status"
            id="hideProductDescriptionTabs"
            className="select input-form-area mt-2 px-2 "
          >
            <option value="0"> {t("Hide Product Description Tabs")}</option>
            <option value="true">{t("active")}</option>
            <option value="false">{t("not active")}</option>
          </select>

          </Tab>

          <Tab
            eventKey="vatValue"
            title={t("Tax Statements")}
            style={{ backgroundColor: "white" }}
          >
            <label htmlFor="vatnumber" className="d-block mt-2 ">
              {" "}
              {t("tax number")}{" "}
            </label>
            <input
              id="vatnumber"
              value={vatNumber}
              onChange={onChangeVatNumber}
              type="text"
              className="input-form d-block mt-2 px-3"
              placeholder={t("tax number")}
            />
            <label htmlFor="vatvalue" className="d-block mt-2 ">
              {" "}
              {t("Tax value")}{" "}
            </label>

            <input
              id="vatvalue"
              value={vatValue}
              onChange={onChangeVatValue}
              type="number"
              className="input-form d-block mt-2 px-3"
              placeholder={t("Tax value")}
            />
            {/*
    
             <select
              onChange={onChangeShippingId}
              value={shippingId}
              name="shipping"
              id="shipping"
              className="select mt-3 px-2 "
            >
              <option value="0">اختر طريقة الشحن الافتراضية</option>
              {shippingData
                ? shippingData.map((el) => {
                  return (
                    <option key={el._id} value={el._id}>
                      {el.nameAr}
                    </option>
                  );
                })
                : null}
            </select>
    */}
          </Tab>
          <Tab
            eventKey="Backup"
            title={t("Backups And Restore")}
            style={{ backgroundColor: "white" }}
          >
            <div className="sub-tile pt-3  w-100  ">
              1- {t("Back up the store")} <BsArrowDownCircleFill />
            </div>
            <br />

            <Button
              disabled={loadingExport}
              onClick={downloadBackups}
              variant="primary"
              className="download-btn"
              style={{ backgroundColor: settingsRes?.data?.primaryColor }}
            >
              {t("backup")} <MdBackup className="download-icon" />
            </Button>
            <br />
            <div className="sub-tile mt-4 w-100  ">
              2- {t("Backup management")} <BsArrowDownCircleFill />
            </div>
            <div>
              <DataTableExtensions
                {...tableData}
                filterPlaceholder={t("search here")}
              >
                <DataTable
                  noHeader
                  defaultSortAsc={false}
                  pagination
                  highlightOnHover
                />
              </DataTableExtensions>
            </div>
            <div className="sub-tile pt-3  w-100  ">
              3- {t("Upload a backup")} <BsArrowDownCircleFill />
            </div>
            <label for="file-input" className="file-input-label mt-3">
              <span className="file-input-label-text">{archiveFile ? archiveFile.name : 'Choose a zip file'}</span>
              <input id="file-input" onChange={onChangeArchiveFile} type="file" accept=".archive,application/archive" className="file-input" />
            </label>
            {
              archiveFile &&
              <Button
                disabled={loadingRestore}
                className="download-btn m-3"
                variant="info"
                onClick={onSubmitUploadFile}>
                {t("Restore")}
                <FcDataBackup style={{ marginRight: '2px' }} />
              </Button>
            }

          </Tab>
        </Tabs>

        {/*<label htmlFor="descriptionEn" className="d-block mt-1 "> وصف الموقع (بالنجليزية)</label>

        <textarea
        id="descriptionEn"
          onChange={'onChangeDescriptionEn'}
          value={'descriptionEn'}
          className="input-form-area p-2 mt-2"
          rows="4"
          cols="50"
          placeholder="وصف الموقع (بالنجليزية)"
  /> */}

        {/*   <select
            onChange={onChangeShippingId}
            value={shippingId}
            name="shipping"
            id="shipping"
            className="select mt-3 px-2 "
          >
            <option value="0">اختر طريقة الشحن الافتراضية</option>
            {shippingData
              ? shippingData.map((el) => {
                return (
                  <option key={el._id} value={el._id}>
                    {el.nameAr}
                  </option>
                );
              })
              : null}
            </select> */}
      </Row>
      <Row>
        <Col sm="12" className="d-flex justify-content-end ">
          <button
            onClick={onSubmit}
            className="btn-save d-inline mt-3 "
            style={{ backgroundColor: settingsRes?.data?.primaryColor }}
          >
            {t("save")}
          </button>
        </Col>
      </Row>
      <ToastContainer />
    </div>
  );
};

export default AdminSettings;
