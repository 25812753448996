import {
  ADD_TO_CART,
  GET_USER_CART_ITEMS,
  DELETE_ALL_CART_ITEMS,
  REMOVE_ONE_ITEM,
  UPDATE_ITEM_CART,
  APPLY_COUPON,
  ADMIN_ALL_CARTS,
  SEND_EMAIL
} from "../type";
const inital = {
  addToCart: [],
  userCartItems: [],
  deleteAllCartItems: [],
  removeOneItem: [],
  updateItemCart: [],
  applyCoupon: [],
  allCartAdmin: [],
  sendEmail: [],

};

const cartReducer = (state = inital, action) => {
  switch (action.type) {
    case ADD_TO_CART:
      return {
        ...state,
        addToCart: action.payload,
      };
    case GET_USER_CART_ITEMS:
      return {
        ...state,
        userCartItems: action.payload,
      };
    case DELETE_ALL_CART_ITEMS:
      return {
        ...state,
        deleteAllCartItems: action.payload,
      };
    case REMOVE_ONE_ITEM:
      return {
        ...state,
        removeOneItem: action.payload,
      };
    case UPDATE_ITEM_CART:
      return {
        ...state,
        updateItemCart: action.payload,
      };
    case APPLY_COUPON:
      return {
        ...state,
        applyCoupon: action.payload,
      };
    case ADMIN_ALL_CARTS:
      return {
        ...state,
        allCartAdmin: action.payload,
      };
    case SEND_EMAIL:
      return {
        ...state,
        sendEmail: action.payload,
      };

    default:
      return state;
  }
};

export default cartReducer;
