import React, { useState, useEffect } from "react";
import ViewSearchProductHooks from "../product/ViewSearchProductHooks";
import { getSettings } from "../../redux/actions/settingsAcions";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

const NavBarSearchHook = () => {
  const [searchWord, setSearchWord] = useState("");
  
 const [, , , getProduct] = ViewSearchProductHooks(searchWord);


  const navigate = useNavigate()
  const location = useLocation()



  // useEffect(() => {
  //   dispatch(getSettings());
  // }, []);

  const settingsRes = useSelector((state) => state.settingsReducers.settings);

  const onChangeSearch = (e) => {
    setSearchWord(e.target.value);
    if (location.pathname !== '/products') {
      navigate('/products')
    }
  };
  useEffect(() => {
    setTimeout(() => {
      if(location.pathname === '/products'){
        getProduct()
      }
      // getProduct();
    }, 1000);
  }, [ location.pathname, searchWord]);

  return [onChangeSearch, searchWord, settingsRes];
};

export default NavBarSearchHook;
