import { combineReducers } from "redux";
import categoryReducer from "./categoryReducer";
import brandReducer from "./brandReducer";
import subCategory from "./subCategoryReducer";
import productReducer from "./productReducer";
import authReducers from "./authReducers";
import reviewReducer from "./reviewReducer";
import favoriteReducers from "./favoriteReducers";
import couponReducer from "./couponReducer";
import userAddressReducer from "./userAddressReducer";
import usersReducers from "./usersReducers";
import cartReducer from "./cartReducer";
import checkoutReducer from "./checkoutReducer";
import paymentMethodReducer from "./paymentMethodReducer";
import orderReducer from "./orderReducer";
import sliderReducer from "./sliderReducer";
import settingsReducers from "./settingsReducers";
import shippingMethodReducer from "./shippingMethodReducer";
import termsPolicyAboutReducer from "./termsPolicyAboutReducer";
import vendorReducer from "./vendorReducer";
import adsReducers from "./adsReducers";
import contactUsReducer from "./contactUsReducer";
import orderHistoryReducer from "./orderHistoryReducer";
import walletReducer from "./walletReducer";
import offersReducers from "./offersReducers";
import excelReducer from "./excelReducer";
import backupRestoreResducer from "./backupRestoreResducer";

export default combineReducers({
  allCategory: categoryReducer,
  allBrand: brandReducer,
  subCategory: subCategory,
  allProduct: productReducer,
  authReducers: authReducers,
  reviewReducer: reviewReducer,
  favoriteReducers: favoriteReducers,
  couponReducer: couponReducer,
  userAddressReducer: userAddressReducer,
  usersReducers: usersReducers,
  cartReducer: cartReducer,
  checkoutReducer: checkoutReducer,
  paymentMethodReducer: paymentMethodReducer,
  orderReducer: orderReducer,
  sliderReducer: sliderReducer,
  settingsReducers: settingsReducers,
  shippingMethodReducer: shippingMethodReducer,
  termsPolicyAboutReducer: termsPolicyAboutReducer,
  vendorReducer: vendorReducer,
  adsReducers: adsReducers,
  contactUsReducer: contactUsReducer,
  orderHistoryReducer: orderHistoryReducer,
  walletReducer: walletReducer,
  offersReducers: offersReducers,
  excelReducer: excelReducer,
  backupRestoreResducer: backupRestoreResducer,
});
