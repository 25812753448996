import { BrowserRouter, Routes, Route } from "react-router-dom";
import NavBarLogin from "./Components/Utilities/NavBarLogin";
import Footer from "./Components/Utilities/Footer";
import LoginPage from "./Pages/Auth/LoginPage";
import RegisterPage from "./Pages/Auth/RegisterPage";
import CategoryPage from "./Pages/Category/CategoryPage";
import BrandPage from "./Pages/Brand/BrandPage";
import ShopProductPage from "./Pages/Product/ShopProductPage";
import ProductDetailsPage from "./Pages/Product/ProductDetailsPage";

import PaymentMethodPage from "./Pages/Checkout/PaymentMethodPage";
import AdminProductsPage from "./Pages/Admin/AdminProductsPage";
import AdminOrdersPage from "./Pages/Admin/AdminOrdersPage";
import AdminOrdersDetailsPage from "./Pages/Admin/AdminOrdersDetailsPage";
import AdminAddBrandPage from "./Pages/Admin/AdminAddBrandPage";
import AdminAddCategoryPage from "./Pages/Admin/AdminAddCategoryPage";
import AdminAddSubCategoryPage from "./Pages/Admin/AdminAddSubCategoryPage";
import AdminAddProductPage from "./Pages/Admin/AdminAddProductPage";
import UserProfilePage from "./Pages/User/UserProfilePage";
import UserAddAddressPage from "./Pages/User/UserAddAddressPage";
import UserAllAddresPage from "./Pages/User/UserAllAddresPage";
import UserAllOrdersPage from "./Pages/User/UserAllOrdersPage";
import UserFavoriteProductsPage from "./Pages/User/UserFavoriteProductsPage";
import UserEditAddressPage from "./Pages/User/UserEditAddressPage";
import AdminEditProductsPage from "./Pages/Admin/AdminEditProductsPage";
import ForgetPassowrdPage from "./Pages/Auth/ForgetPassowrdPage";
import AdminCouponsPage from "./Pages/Admin/AdminCouponsPage";
import AdminEditCouponPage from "./Pages/Admin/AdminEditCouponPage";
import AdminAllUsersPage from "./Pages/Admin/AdminAllUsersPage";
import ProtectedRouteHook from "./hook/auth/ProtectedRouteHook";
import ProtectedRoute from "./Components/Utilities/ProtectedRoute";
import AdminAddSliderPage from "./Pages/Admin/AdminAddSliderPage";
import AdminAddAdsPage from "./Pages/Admin/AdminAddAdsPage";
import AdminEditUserPage from "./Pages/Admin/AdminEditUserPage";
import AdminSettingsPage from "./Pages/Admin/AdminSettingsPage";
import AdminTermsPage from "./Pages/Admin/AdminTermsPage";
import AdminAboutPage from "./Pages/Admin/AdminAboutPage";
import AdminPolicyPage from "./Pages/Admin/AdminPolicyPage";
import TermsAboutPlicyPage from "./Pages/Utilities/TermsAboutPlicyPage";
import ContactusPage from "./Pages/Utilities/ContactusPage";
import AdminCartReportPage from "./Pages/Admin/AdminCartReportPage";
import AdminCartReportDetailsPage from "./Pages/Admin/AdminCartReportDetailsPage";
import ProductsByCategory from "./Pages/Product/ProductsByCategory";
import ProductsBySubCategory from "./Pages/Product/ProductsBySubCategory";
import ProductsByBrands from "./Pages/Product/ProductsByBrands";
import ReactGA from "react-ga";
import AdmingoogleAnalyticsPage from "./Pages/Admin/AdmingoogleAnalyticsPage";
import AdminProductSalesReportPage from "./Pages/Admin/AdminProductSalesReportPage";
import AdminPayMethodsPage from "./Pages/Checkout/AdminPayMethodsPage";
import EditPaymentMethodPage from "./Pages/Checkout/EditPaymentMethodPage";
import AdminAddUserPage from "./Pages/Admin/AdminAddUserPage";
import VendorProfilePage from "./Pages/Vendor/VendorProfilePage";
import VendorAllOrdersPage from "./Pages/Vendor/VendorAllOrdersPage";
import VendorAllProductsPage from "./Pages/Vendor/VendorAllProductsPage";
import AdminAddCouponPage from "./Pages/Admin/AdminAddCouponPage";
import AdminCategoriesPage from "./Pages/Admin/AdminCategoriesPage";
import AdminEditCategoryPage from "./Pages/Admin/AdminEditCategoryPage";
import AdminViewsliderPage from "./Pages/Admin/AdminViewsliderPage";
import AdminEditSliderPage from "./Pages/Admin/AdminEditSliderPage";
import { Suspense, useEffect, lazy } from "react";
import AdminSubCategoriesPage from "./Pages/Admin/AdminSubCategoriesPage";
import AdminBrandsPage from "./Pages/Admin/AdminBrandsPage";
import AdminEditBrandPage from "./Pages/Admin/AdminEditBrandPage";
import AdminShippingMethodPage from "./Pages/Admin/AdminShippingMethodPage";
import AdminEditShippingMethod from "./Components/Admin/AdminEditShippingMethod";
import AdminEditShippingMethodPage from "./Pages/Admin/AdminEditShippingMethodPage";
import AdminAdsPage from "./Pages/Admin/AdminAdsPage";
import AdminEditAdsPage from "./Pages/Admin/AdminEditAdsPage";
import AdminProfilePage from "./Pages/Admin/AdminProfilePage";
import UserVerify from "./Pages/Auth/UserVerify";
import ProductByVendorPage from "./Pages/Product/ProductByVendorPage";
import { Helmet } from "react-helmet";
import AdminTicketsPage from "./Pages/Admin/AdminTicketsPage";
import PaymentStatusPage from "./Pages/User/PaymentStatusPage";
import AdminUserTransactionPage from "./Pages/Admin/AdminUserTransactionPage";
import AdminAddTransactionPage from "./Pages/Admin/AdminAddTransactionPage";
import AdminEditTransactionPage from "./Pages/Admin/AdminEditTransactionPage";
import WalletUserTransactionPage from "./Pages/User/WalletUserTransactionPage";
import ResetPasswordPage from "./Pages/Auth/ResetPasswordPage";
import OffersPage from "./Pages/Offers/OffersPage";
import AddOfferPage from "./Pages/Offers/AddOfferPage";
import EditOfferPage from "./Pages/Offers/EditOfferPage";
import OfferDetailsPage from "./Pages/Offers/Offer-Details/OfferDetailsPage"
import AdminExcelPage from "./Pages/Admin/AdminExcelPage.js";
import VendorExcelPage from "./Pages/Vendor/VendorExcelPage";
import OfferProductPage from "./Pages/Product/OfferProductPage";
import VendorOfferPage from "./Pages/Vendor/VendorOfferPage";
import VendorOfferDetailsPage from "./Pages/Vendor/VendorOfferDetailsPage"
import AdminProductReviewsPage from "./Pages/Admin/AdminProductReviewsPage";
import VideosLearningPage from "./Pages/Utilities/VideosLearningPage";
import SubCategoryCard from "./Components/SubCategory/SubCategoryCard";
import SubCategoryPage from "./Pages/SubCategory/SubCategoryPage";


const CartPage = lazy(() => import("./Pages/Cart/CartPage"));
const HomePage = lazy(() => import("./Pages/Home/HomePage"));


ReactGA.initialize("G-WBJPY8HCES");

function App() {
  const [isUser, isAdmin, isVendor, userData, settingsRes] =
    ProtectedRouteHook();

  return (
    <div className="font">
      <Helmet>
        <title>{settingsRes.data?.titleAr}</title>
        <meta name="description" content={settingsRes.data?.descriptionSite} />
        <meta name="keywords" content={settingsRes.data?.keywordsSite} />
      </Helmet>
      <BrowserRouter>
        <NavBarLogin />
        <Suspense fallback="Loading ..........">
          <Routes>
            {/* All Routes For Any One  */}

            <Route index element={<HomePage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/allcategory" element={<CategoryPage />} />
            <Route path="/allbrand" element={<BrandPage />} />
            <Route path="/products" element={<ShopProductPage />} />
            <Route path="/verifyCode/:id" element={<UserVerify />} />
            <Route path="/:vendorName" element={<ProductByVendorPage />} />
            <Route path="/homeOffers" element={<OfferProductPage />} />

            <Route
              path="/products/category/:id"
              element={<ProductsByCategory />}
            />
            <Route
              path="/products/subCategory/:id"
              element={<ProductsBySubCategory />}
            />

            <Route
              path="/category/subcategory/:id"
              element={<SubCategoryPage />}
            />

            <Route path="/products/brand/:id" element={<ProductsByBrands />} />

            <Route path="/product/:id" element={<ProductDetailsPage />} />
            <Route path="/cart" element={<CartPage />} />
            <Route path="/:name/:type" element={<TermsAboutPlicyPage />} />
            <Route path="/contactUs" element={<ContactusPage />} />
            <Route
              path="/user/forgetpassword"
              element={<ForgetPassowrdPage />}
            />
            <Route path="/user/ResetPassword" element={<ResetPasswordPage />} />

            {/* All Route When Admin logged */}
            <Route element={<ProtectedRoute auth={isAdmin} />}>
              <Route path="/admin/products" element={<AdminProductsPage />} />
              <Route path="/admin/orders" element={<AdminOrdersPage />} />
              <Route
                path="/admin/orders/:id"
                element={<AdminOrdersDetailsPage />}
              />
              <Route path="/admin/settings" element={<AdminSettingsPage />} />
              <Route path="/admin/terms" element={<AdminTermsPage />} />
              <Route path="/admin/about" element={<AdminAboutPage />} />
              <Route path="/admin/policy" element={<AdminPolicyPage />} />
              <Route path="/admin/sliders" element={<AdminAddSliderPage />} />
              <Route path="/admin/addAds" element={<AdminAddAdsPage />} />
              <Route
                path="/admin/editsliders/:id"
                element={<AdminEditSliderPage />}
              />
              <Route path="/admin/editAds/:id" element={<AdminEditAdsPage />} />
              <Route
                path="/admin/viewslider"
                element={<AdminViewsliderPage />}
              />
              <Route
                path="/admin/paymethoudAdmin"
                element={<AdminPayMethodsPage />}
              />
              <Route
                path="/admin/shippingMethod"
                element={<AdminShippingMethodPage />}
              />
              <Route path="/admin/addbrand" element={<AdminAddBrandPage />} />
              <Route
                path="/admin/editBrand/:id"
                element={<AdminEditBrandPage />}
              />
              <Route
                path="/admin/productSalesReport"
                element={<AdminProductSalesReportPage />}
              />
              <Route
                path="/admin/categories"
                element={<AdminCategoriesPage />}
              />
              <Route path="/admin/brands" element={<AdminBrandsPage />} />
              <Route path="/admin/ads" element={<AdminAdsPage />} />
              <Route
                path="/admin/subcategories/:id"
                element={<AdminSubCategoriesPage />}
              />
              <Route
                path="/admin/editCategory/:id"
                element={<AdminEditCategoryPage />}
              />
              <Route
                path="/admin/addcategory"
                element={<AdminAddCategoryPage />}
              />
              <Route
                path="/admin/googleAnalytics"
                element={<AdmingoogleAnalyticsPage />}
              />
              <Route
                path="/admin/addsubcategory/:id"
                element={<AdminAddSubCategoryPage />}
              />
              <Route
                path="/admin/addproduct"
                element={<AdminAddProductPage type="admin" />}
              />
              <Route path="/admin/coupons" element={<AdminCouponsPage />} />
              <Route path="/admin/addcoupon" element={<AdminAddCouponPage />} />
              <Route
                path="/admin/editProduct/:id"
                element={<AdminEditProductsPage type="admin" />}
              />
              <Route path="/admin/alluser" element={<AdminAllUsersPage />} />
              <Route
                path="/admin/editcoupon/:id"
                element={<AdminEditCouponPage />}
              />
              <Route
                path="/admin/editPaymentMethod/:id"
                element={<EditPaymentMethodPage />}
              />
              <Route
                path="/admin/editShippingMethod/:id"
                element={<AdminEditShippingMethodPage />}
              />
              <Route
                path="/admin/edituser/:id"
                element={<AdminEditUserPage />}
              />
              <Route
                path="/admin/cartReport"
                element={<AdminCartReportPage />}
              />
              <Route
                path="/admin/cartReportDetails/:id"
                element={<AdminCartReportDetailsPage />}
              />
              <Route
                path="/admin/userTransactions/:id"
                element={<AdminUserTransactionPage />}
              />
              AdminEditTransactionPage
              <Route
                path="/admin/addTransaction/:id"
                element={<AdminAddTransactionPage />}
              />
              <Route
                path="/admin/eeditTransaction/:id/:userId"
                element={<AdminEditTransactionPage />}
              />

              <Route path="/admin/addUser" element={<AdminAddUserPage />} />
              <Route path="/admin/profile" element={<AdminProfilePage />} />
              <Route path="/admin/tickets" element={<AdminTicketsPage />} />
              <Route path="/admin/Offers" element={<OffersPage />} />
              <Route path="/admin/addOffer" element={<AddOfferPage />} />
              <Route path="/admin/editOffer/:id" element={<EditOfferPage />} />
              <Route path="/admin/offerDetails/:id" element={< OfferDetailsPage />} />
              <Route path="/admin/excel" element={<AdminExcelPage />} />
              <Route path="/admin/reviews/:productId" element={<AdminProductReviewsPage />} />
              <Route path="/admin/learning" element={<VideosLearningPage />} />




            </Route>

            {/* All Routes When Vendor Logged  */}
            <Route element={<ProtectedRoute auth={isVendor} />}>
              <Route
                path="/vendor/orders/:id"
                element={<AdminOrdersDetailsPage />}
              />

              <Route path="/vendor/profile" element={<VendorProfilePage />} />
              <Route
                path="/vendor/allorders"
                element={<VendorAllOrdersPage />}
              />
              <Route
                path="/vendor/allproducts"
                element={<VendorAllProductsPage />}
              />
              <Route
                path="/vendor/editProduct/:id"
                element={<AdminEditProductsPage />}
              />
              <Route
                path="/vendor/addproduct"
                element={<AdminAddProductPage />}
              />
              <Route path="/vendor/excel" element={<VendorExcelPage />} />
              <Route
                path="/vendor/offers"
                element={<VendorOfferPage />}
              />
              <Route path="/vendor/addOffer" element={<AddOfferPage />} />
              <Route path="/vendor/editOffer/:id" element={<EditOfferPage />} />
              <Route path="/vendor/offerDetails/:id" element={< VendorOfferDetailsPage />} />
              <Route path="/vendor/learning" element={<VideosLearningPage />} />


            </Route>

            {/* All Routes When user Logged   */}
            <Route element={<ProtectedRoute auth={isUser} />}>
              <Route path="/user/allorders" element={<UserAllOrdersPage />} />
              <Route
                path="/user/favoriteproducts"
                element={<UserFavoriteProductsPage />}
              />
              <Route path="/user/addresses" element={<UserAllAddresPage />} />
              <Route
                path="/user/add-address"
                element={<UserAddAddressPage />}
              />
              <Route
                path="/user/edit-address/:id"
                element={<UserEditAddressPage />}
              />
              <Route path="/user/profile" element={<UserProfilePage />} />
              <Route path="/order/paymethoud" element={<PaymentMethodPage />} />

              <Route
                path="/order/paymentStatus"
                element={<PaymentStatusPage />}
              />
              <Route
                path="/user/wallet"
                element={<WalletUserTransactionPage />}
              />
            </Route>
          </Routes>
        </Suspense>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
