import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllProducts,
  getAllProductsAdmin,
  getAllProductsWithPage,
  updateProduct,
  updateProductStatus,
} from "../../redux/actions/productsAction";
import { getSettings } from "../../redux/actions/settingsAcions";
import notify from "../useNotification";

const ViewProductAdminHook = () => {

  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch();


  useEffect(() => {
    if(localStorage.getItem('page')){
      // console.log('page')
      // console.log(localStorage.getItem('page'))
      getPage(localStorage.getItem('page'))
    }else{

      dispatch(getAllProductsAdmin(9));
    }
  }, []);
  const settingsRes = useSelector((state) => state.settingsReducers.settings);
  const allproduct = useSelector((state) => state.allProduct.allProductsAdmin);
  const getPage = async (page) => {
    localStorage.setItem('page',page)
    await dispatch(getAllProductsWithPage(page, 9));
  };
  let items = [];
  if (allproduct)
    if (allproduct.data) items = allproduct.data;
    else items = [];
  let pagination = [];
  if (allproduct)
    if (allproduct.numberOfPages) pagination = allproduct.numberOfPages;
    else pagination = [];


  const handleShowFun = async (id, status) => {
    setLoading(true);
    await dispatch(
      updateProductStatus(id, {
        status: status,
      })
    );
    setLoading(false);
  };
  const ress = useSelector((state) => state.allProduct.updateProductStatus);

  useEffect(() => {

    if (loading === false) {
      if (ress) {
        notify("تم التعديل بنجاح", "success");
        setTimeout(() => {
          dispatch(getAllProductsAdmin(9));
        }, 1000);
      } else {
        notify("فشل في عملية التعديل", "error");
      }
    }
  }, [loading]);




  return [items, pagination, getPage, handleShowFun, settingsRes];
};

export default ViewProductAdminHook;
