import React, { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import rate from "../../Images/rate.png";
import { ToastContainer } from "react-toastify";
import deleteicon from "../../Images/delete.png";
import editicon from "../../Images/edit.png";
import DeleteRateHook from "../../hook/rate/DeleteRateHook";
import EditRateHook from "../../hook/rate/EditRateHook";
import ReactStars from "react-rating-stars-component";
import { useTranslation } from "react-i18next";

const RateItem = ({ review }) => {
  const { t } = useTranslation();

  const [isUser, handleDelete, handleShow, handleClose, showDelete] =
    DeleteRateHook(review);
  const [
    handleEdit,
    handleCloseEdit,
    showEdit,
    handleShowEdit,
    onChangeRateText,
    newRateText,
    onChangeRateValue,
    newRateValue,
  ] = EditRateHook(review);
  const setting = {
    size: 20,
    count: 5,
    color: "#979797",
    activeColor: "#ffc107",
    value: newRateValue,
    a11y: true,
    isHalf: true,
    emptyIcon: <i className="far fa-star" />,
    halfIcon: <i className="fa fa-star-half-alt" />,
    filledIcon: <i className="fa fa-star" />,
    onChange: (newValue) => {
      onChangeRateValue(newValue);
    },
  };
  return (
    <div>
      {/* model for edit */}
      <Modal show={showEdit} onHide={handleCloseEdit}>
        <Modal.Header>
          <Modal.Title>
            <div className="font">{t("Edit rating")}</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ReactStars {...setting} />
          <input
            value={newRateText}
            type="text"
            onChange={onChangeRateText}
            className="font w-100 p-2"
            style={{ border: "none" }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="font"
            variant="secondary"
            onClick={handleCloseEdit}
          >
            {t("retreat")}
          </Button>
          <Button className="font" variant="danger" onClick={handleEdit}>
            {t("Modify")}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* model for delete */}
      <Modal show={showDelete} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>
            <div className="font">{t("Confirm the deletion")}</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="font">{t("are sure of the deleting process")}</div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="font" variant="secondary" onClick={handleClose}>
            {t("retreat")}
          </Button>
          <Button className="font" variant="danger" onClick={handleDelete}>
            {t("delete")}
          </Button>
        </Modal.Footer>
      </Modal>
      <Row className="mt-3">
        <Col className="d-flex justify-content-start pt-4">
          <div
            className="rate-name  d-inline ms-2"
            style={{ color: "#d355a3" }}
          >
            {review.user.firstname}
          </div>
          <div>
            <img className="" src={rate} alt="" height="16px" width="16px" />
            <div className="cat-rate  d-inline  p-1 pt-2">{review.rate}</div>
          </div>
        </Col>
      </Row>
      <Row className="border-bottom mx-2">
        <Col className=" pb-2">
          <div className="rate-description  d-inline ms-2">
            {review.comment}
          </div>
          {isUser === true ? (
            <div className="d-inline d-flex justify-content-end">
              <img
              className="m-1"
                onClick={handleShow}
                src={deleteicon}
                alt="delete"
                width="25px"
                height="25px"
                style={{ cursor: "pointer", marginLeft: "5px" }}
              />

              <img
              className="m-1"
                onClick={handleShowEdit}
                src={editicon}
                alt="edit"
                width="25px"
                height="25px"
                style={{ cursor: "pointer" }}
              />
            </div>
          ) : null}
        </Col>
      </Row>
      <ToastContainer />
    </div>
  );
};

export default RateItem;
