import  { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  
  getAllCartItems,
} from "../../redux/actions/cartAction";

const UserCartHook = () => {
  var user = "";
  if (localStorage.getItem("user") != null) {
    user = JSON.parse(localStorage.getItem("user"));
  }
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [itemNumber, setItemsNumber] = useState(0);
  const [cartItems, setCartItems] = useState([]);
  const [couponNameAfterApplay, setCouponNameAfterApplay] = useState("");
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalPriceAfterDiscount, setTotalPriceAfterDiscount] = useState(0);

  const [vendorId, setvendorId] = useState('');
  const [couponId, steCouponId] = useState('');

  useEffect(() => {
    if (user != "") {
      const get = async () => {
        setLoading(true);
        await dispatch(getAllCartItems(user._id));
        setLoading(false);
      };
      get();
    }
  }, []);
  const res = useSelector((state) => state.cartReducer.userCartItems);

  useEffect(() => {
    if (loading === false) {
      if (res && res.success === true) {
        setItemsNumber(res.numberOfCart);
        setCartItems(res.data);
        setvendorId(res.data[0]?.product?.vendor)
        setTotalPrice(res.totalPrice);
        if (res.data.length != 0 && res.data[0].coupon) {
          setCouponNameAfterApplay(res.data[0].coupon);
          steCouponId(res.data[0].couponId);

        } else {
          setCouponNameAfterApplay("");
          steCouponId("");
        }
        if (res.data.length != 0 && res.data[0].priceAfter) {
          setTotalPriceAfterDiscount(res.data[0].priceAfter);
        } else {
          setTotalPriceAfterDiscount(0);
        }
      } else {
        setItemsNumber(0);
        setCartItems([]);
        setvendorId("");

        setCouponNameAfterApplay("");
        steCouponId("");
        setTotalPriceAfterDiscount(0);
      }
    }
  }, [loading]);

  return [
    res,
    cartItems,
    totalPrice,
    couponNameAfterApplay,
    totalPriceAfterDiscount,
    vendorId,
    couponId
  ];
};

export default UserCartHook;
