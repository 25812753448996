import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import {
  getAllProductsSearch
} from "../../redux/actions/productsAction";

const ViewSearchProductHooks = (searchWord, subId, categoryId , brandId) => {
  //const location = useLocation();
  //console.log(window.location.pathname);

  const [wordSearch, setWordSearch] = useState("");
  const [loadingSpinner, setLoadingSpinner] = useState(true);

  let [items, setItems] = useState([]);
  let [offers, setOffers] = useState([]);

  const [searchCategories, setSearchCategories] = useState([]);
  const [searchBrand, setSearchBrand] = useState([]);

  const [searchPaginationCategories, setSearchPaginationCategories] = useState(
    []
  );
  const [searchPaginationBrand, setPaginationSearchBrand] = useState([]);

  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);
  const [flag, setFlag] = useState(0);
  const location = useLocation()

  async function onChangeSetMinPrice(e) {
    setMinPrice(e.target.value);
    setFlag(1);
  }

  async function onChangeSetMaxPrice(e) {
    setMaxPrice(e.target.value);
    setFlag(1);
  }

  // console.log(searchWord)
  let limit = 8;
  const dispatch = useDispatch();

  const getProduct = async () => {
    setWordSearch(searchWord);
    let word = "";
    if (searchWord !== undefined && searchWord.trim !== '') word = wordSearch;
    sortData();

    if (location?.pathname === "/homeOffers") {
      // console.log("first");
      setLoadingSpinner(true);
      await dispatch(getAllProductsSearch(`limit=200&page=1&showOffer=true`));
      setLoadingSpinner(false);
    } else {
      // console.log('second')
      // console.log('subId')
      // console.log(subId);
      setLoadingSpinner(true);
      await dispatch(
        getAllProductsSearch(
          `sort=${sort}&limit=${limit}&categories=${categoryId}&brand=${brandId}&keyword=${word}&subCategory=${subId}`
        )
      );
      setLoadingSpinner(false);
    }
  };

  useEffect(() => {
    getProduct();
    window.scrollTo(0, 0);
  }, []);

  const allproduct = useSelector((state) => state.allProduct.allProducts);

  // const offers=0;
  //const offers = useSelector((state) => state.allProduct.allProducts);

  
// this use effect called in products by category page
  useEffect(() => {
    if (categoryId) {
      setSearchCategories([categoryId]);
    }
  }, [categoryId]);


  // this use effect called in products by brand 
  useEffect(() => {
    if (brandId) {
      setSearchBrand([brandId]);
    }
  }, [brandId]);

  const onPress = async (page) => {
    let word = "";
    if (searchWord != undefined) word = wordSearch;
    sortData();

    let search_brand_param = searchPaginationBrand;
    let search_categories_param = searchPaginationCategories;

    if (!searchPaginationBrand || searchPaginationBrand.length === 0) {
      //console.log('no brands');
      setSearchBrand([0]);
      search_brand_param = 0;
    }

    if (
      !searchPaginationCategories ||
      searchPaginationCategories.length === 0
    ) {
      setSearchCategories([0]);
      search_categories_param = 0;
    }

    let pagination_url = "";
    if (location?.pathname === "/homeOffers") {
      // console.log('offers url');

      if (
        searchPaginationCategories ||
        searchPaginationBrand ||
        minPrice ||
        maxPrice
      ) {
        pagination_url = `limit=${limit}&page=${page}&brand=${search_brand_param}&categories=${search_categories_param}&minPrice=${minPrice}&maxPrice=${maxPrice}&showOffer=true&subCategory=${subId}`;
        // await dispatch(
        //   getAllProductsSearch(
        //     `limit=${limit}&page=${page}&brand=${search_brand_param}&categories=${search_categories_param}&minPrice=${minPrice}&maxPrice=${maxPrice}&showOffer=true`
        //   )
        // );
      } else {
        //no categories or brand
        pagination_url = `sort=${sort}&limit=${limit}&page=${page}&keyword=${word}&showOffer=true&subCategory=${subId}`;
        // await dispatch(
        //   getAllProductsSearch(
        //     `sort=${sort}&limit=${limit}&page=${page}&keyword=${word}&showOffer=true`
        //   )
        // );
      }
    } else {
      if (
        searchPaginationCategories ||
        searchPaginationBrand ||
        minPrice ||
        maxPrice
      ) {
        pagination_url = `limit=${limit}&page=${page}&brand=${search_brand_param}&categories=${search_categories_param}&minPrice=${minPrice}&maxPrice=${maxPrice}&subCategory=${subId}`;
        // await dispatch(
        //   getAllProductsSearch(
        //     `limit=${limit}&page=${page}&brand=${search_brand_param}&categories=${search_categories_param}&minPrice=${minPrice}&maxPrice=${maxPrice}`
        //   )
        // );
      } else {
        //no categories or brand
        pagination_url = `sort=${sort}&limit=${limit}&page=${page}&keyword=${word}&subCategory=${subId}`;

        //   await dispatch(
        //     getAllProductsSearch(
        //       `sort=${sort}&limit=${limit}&page=${page}&keyword=${word}`
        //     )
        //   );
        // }
      }
    }

    await dispatch(getAllProductsSearch(pagination_url));
  };

  //let allItems = [];
  //const[initialItems,items]=useState([]);
  let pagination = [];
  let result = 0;

  useEffect(() => {
    if (location?.pathname !== "/homeOffers") {
      if (allproduct) {
        if (allproduct.data) {
          setItems(allproduct.data);
        }
      }
    } else {
      //setOffers
      if (allproduct) {
        if (allproduct.data) {
          setOffers(allproduct.data);
        }
      }
    }
  }, [allproduct]);

  try {
    if (allproduct)
      if (allproduct.numberOfPages) pagination = allproduct.numberOfPages;
      else pagination = [];
  } catch (error) {}
  try {
    if (allproduct)
      if (allproduct.total) result = allproduct.total;
      else result = 0;
  } catch (error) {}

  let sortType = "",
    sort = "";
  const sortData = () => {
    if (localStorage.getItem("sortType") !== null) {
      sortType = localStorage.getItem("sortType");
    } else {
      sortType = "";
    }
    if (sortType === "السعر من الاقل للاعلي") sort = "+price";
    else if (sortType === "السعر من الاعلي للاقل") sort = "-price";
    else if (sortType === "") sort = "";
  };

  let old_category_value = "";
  async function selectedCategorySearch(e) {
    //add item on check
    if (e.target.checked === true) {
      if (e.target.value === "0") {
        setSearchCategories([]);
      } else {
        if (old_category_value == e.target.value) {
        } else {
          setSearchCategories([...searchCategories, e.target.value]);
        }
      }
    }
    //remove item on uncheck
    else {
      let index = searchCategories.indexOf(e.target.value);
      if (index > -1) {
        // only splice array when item is found
        searchCategories.splice(index, 1); // 2nd parameter means remove one item only
      }

      old_category_value = e.target.value;
    }
    setFlag(1);
  }

  //let brand_counter=0;
  let old_brand_value = "";
  async function selectedBrandSearch(e) {
    // add items on check
    if (e.target.checked === true) {
      if (e.target.value === "0") {
        setSearchBrand([]);
      } else {
        if (old_brand_value == e.target.value) {
        } else {
          setSearchBrand([...searchBrand, e.target.value]);
        }
      }
    }
    //remove items on uncheck
    else {
      let index1 = searchBrand.indexOf(e.target.value);
      if (index1 > -1) {
        // only splice array when item is found
        searchBrand.splice(index1, 1); // 2nd parameter means remove one item only
      }
    }

    old_brand_value = e.target.value;
    setFlag(1);
  }

  async function search(searchFlag) {
    let search_brand_param = searchBrand;
    let search_categories_param = searchCategories;

    // if(old_category_value )

    if (searchFlag === 0) {
      if (!searchBrand || searchBrand.length === 0) {
        //console.log('no brands');
        setSearchBrand([]);
        search_brand_param = 0;
      }

      if (!searchCategories || searchCategories.length === 0) {
        setSearchCategories([]);
        search_categories_param = 0;
      }

      if (searchBrand.indexOf("0") !== -1) {
        search_brand_param = 0;
        setSearchBrand([]);
      }

      if (searchCategories.indexOf("0") !== -1) {
        search_categories_param = 0;
        setSearchCategories([]);
      }

      if (!minPrice) {
        setMinPrice(0);
      }

      if (!maxPrice) {
        setMaxPrice(0);
      }

      let url = "";
      if (location?.pathname === "/homeOffers") {
        url = `limit=${8}&page=${1}&brand=${search_brand_param}&categories=${search_categories_param}&minPrice=${minPrice}&maxPrice=${maxPrice}&showOffer=true`;
      } else {
        url = `limit=${8}&page=${1}&brand=${search_brand_param}&categories=${search_categories_param}&minPrice=${minPrice}&maxPrice=${maxPrice}&subCategory=${subId}`;
      }

      if (flag) {
        await dispatch(getAllProductsSearch(url));
      }

      setSearchPaginationCategories(searchCategories);
      setPaginationSearchBrand(searchBrand);
    } else {
      //remove check box
      let brandCheckBox = document.getElementsByClassName("brand");
      for (var i = 0; i < brandCheckBox.length; i++) {
        brandCheckBox[i].checked = false;
      }

      let categoryCheckBox = document.getElementsByClassName("category");
      for (var i = 0; i < categoryCheckBox.length; i++) {
        categoryCheckBox[i].checked = false;
      }

      let url = "";
      if (location?.pathname === "/homeOffers") {
        url = `limit=${8}&page=${1}&brand=${0}&categories=${0}&minPrice=${0}&maxPrice=${0}&showOffer=true`;
      } else {
        url = `limit=${8}&page=${1}&brand=${0}&categories=${0}&minPrice=${0}&maxPrice=${0}&subCategory=${subId}`;
      }

      await dispatch(getAllProductsSearch(url));
    }
  }

  return [
    items,
    pagination,
    onPress,
    getProduct,
    result,
    offers,
    selectedCategorySearch,
    selectedBrandSearch,
    search,
    onChangeSetMinPrice,
    onChangeSetMaxPrice,
    minPrice,
    maxPrice,
    loadingSpinner,
  ];
};

export default ViewSearchProductHooks;
