import React from "react";
import BrandContainer from "../../Components/Brand/BrandContainer";
import Pagination from "../../Components/Utilities/Pagination";
import BrandPageHook from "../../hook/brand/BrandPageHook";
const BrandPage = () => {
  const [brand, loading, pageCount, getPage] = BrandPageHook();
  

  return (
    <div style={{ minHeight: "670px" }}>
      <BrandContainer data={brand?.filter(brand=>brand?.status === true)} loading={loading} />
      <Pagination pageCount={pageCount} onPress={getPage} />
    </div>
  );
};

export default BrandPage;
