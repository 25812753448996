import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import OrderPayCashHook from "../../hook/checkout/AddPaymentMethodHook";
import AddPaymentMethodHook from "../../hook/checkout/AddPaymentMethodHook";
import PaymentMethodCard from "./PaymentMethodCard";
import { useTranslation } from "react-i18next";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import DataTable from "react-data-table-component";
import Cookies from "js-cookie";

import { Helmet } from "react-helmet";


const PayMethodsAdmin = () => {
  const { t } = useTranslation();

  const [
    paymentMethodName_Ar,
    paymentMethodName_En,
    onChangePayMethodName_Ar,
    onChangePayMethodName_En,
    allPaymentMethods,
    onSubmit,
    settingsRes,
    clickHandler,
    handleShowFun
  ] = AddPaymentMethodHook();

  let columns = [
    {
      name: t("nameAr"),
      selector: "nameAr",
      sortable: true,
    },
    {
      name: t("nameEn"),
      selector: "nameEn",
      sortable: true,
    },
    {
      name: t("Modify"),
      cell: (row) => (
        <Button
          variant="secondary"
          onClick={() => clickHandler(row._id)}
          id={row._id}
        >
          {t("Modify")}
          <i className="fa fa-pencil-square-o mx-1" />
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: t("the status"),
      cell: (row) =>
        row.status == true ? (
          <Button
            variant="danger"
            onClick={() => {
              handleShowFun(row._id, false);
            }}
            id={row._id}
          >
            {t("block")}
            <i className="fa fa-lock mx-1" />
          </Button>
        ) : (
          <Button
            variant="success"
            onClick={() => {
              handleShowFun(row._id, true);
            }}
            id={row._id}
          >
           {t("active")}
            <i className="fa fa-unlock mx-1" />
          </Button>
        ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const tableData = {
    columns,
    data: allPaymentMethods,
  };


 
  
  return (
    <div>

    <Helmet>
    <title>{t("paymend methods")} - {Cookies.get('i18next') === 'ar' ? settingsRes.data?.titleAr : settingsRes.data?.titleEn} </title>
  </Helmet>

      <div className="d-flex justify-content-between">
        <div className="admin-content-text">{t("paymend methods")} </div>
      </div>

      <DataTableExtensions {...tableData} filterPlaceholder={t("search here")}>
        <DataTable
          noHeader
          defaultSortAsc={false}
          pagination
          highlightOnHover
        />
      </DataTableExtensions>
      <ToastContainer />
    </div>
  );
};

export default PayMethodsAdmin;


// <Row className="justify-content-start ">
// <div className="admin-content-text py-3">{t("paymend methods")}</div>