import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import CardContainerHook from "../../hook/product/CardContainerHook";
import { favoriteByUserId } from "../../redux/actions/favoriteActions";
import SubTitle from "../Utilities/SubTitle";
import ProductCard from "./ProductCard";
import LoadingSpinner from "../Utilities/LoadingSpinner";
import { useTranslation } from "react-i18next";

const CardProductsContainer = ({ title, btntitle, path, products }) => {
  const [favProduct] = CardContainerHook();
  const [loadingSpinnerTime, setLoadingSpinnerTime] = useState(true);

  const {t} = useTranslation()

  useEffect(() => {
    if (products) {
      // console.log('productsswwwww')
      // console.log(products)
      
      setTimeout(setLoadingSpinnerTime(false), 1000);
    }
  }, [products]);
  //setTimeout(setLoadingSpinnerTime(false), 2000);
  //if(products)
  return (
    <Container>
      <SubTitle title={title} btntitle={btntitle} path={path} />
      <Row className="my-1 d-flex justify-content-md-start justify-content-center">
        {loadingSpinnerTime === true ? (
          <LoadingSpinner />
        ) : (
          <>
            {products
              ? products.map((item) => (
                  <ProductCard
                    favProduct={favProduct}
                    key={item._id}
                    item={item}
                  />
                ))
              : ""}

            
              {products.length === 0 && loadingSpinnerTime === false ? (
              <h6 style={{ textAlign: "center" }}>{t("no products")}  </h6>
            ) : (
              ""
            )} 
            
            
          </>
        )}
      </Row>
    </Container>
  );
};

export default CardProductsContainer;
