import React from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import CategoryHeader from "../../Components/Category/CategoryHeader";
import CardProductsContainer from "../../Components/Products/CardProductsContainer";
import ProductDetails from "../../Components/Products/ProductDetails";
import RateContainer from "../../Components/Rate/RateContainer";
import ViewProductDetails from "../../hook/product/ViewProductDetailsHooks";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const ProductDetailsPage = () => {
  const { id } = useParams();
  const [item, images, prod] = ViewProductDetails(id);
  const settingsData = useSelector((state) => state.settingsReducers.settings);

  const {t} = useTranslation()

  if (item) {
    var rateAv = item.ratingsAverage;
    var reateQty = item.ratingsQuantity;
  }

  return (
    <div style={{ minHeight: "670px" }}>
    <Helmet>

    <meta name="description" content={item.descriptionProduct}/>
    <meta name="keywords" content={item.keywordsProducts} />
  </Helmet>

      {settingsData?.data && settingsData?.data?.showNavHeader === true ? <CategoryHeader /> : null}  

      <Container>
        <ProductDetails />
        <RateContainer rateAv={rateAv} reateQty={reateQty} />
        <CardProductsContainer products={prod} title={t("Products you may like")} />
      </Container>
    </div>
  );
};

export default ProductDetailsPage;
